.payment-record-modal {
    width: calc(100vw - 100px) !important;
    max-width: 1600px !important;

    .ant-modal-content {
        min-height: 600px;
        max-height: calc(100vh - 100px) !important;
        overflow-y: auto;
    }
}
