.active-user-title{
    overflow: hidden;
    font-size: 18px;
    margin-bottom: 20px;
    .table-toolbar{
        float: right;
    }
}

.main-content-box-active{
    .bi-user-overview-time-box{
        .ant-picker-input > input{
            font-size: 14px !important;
            padding: 0 !important;
            width: 75px;
        }
        margin-bottom: 25px;
    }
}

.active-user-ehcarts{
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
}
