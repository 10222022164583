.guest-selection-area {
  display: flex;
  min-height: 100%;

  .guests-to-select {
    flex: 1;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 16px;

  }

  .operations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;

    .ant-btn {
      margin: 8px 0;
    }
  }

  .guests-selected {
    flex: 1;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 16px;

  }

  .guest-selection-title {
    //font-size: 18px;
    margin-bottom: 16px;
  }

  .guest-selection-filter {
    margin-bottom: 16px;
  }

  .form-guest-item {
    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-select .ant-select-selection-search-input {
      height: 24px !important;
    }
  }

}

.hidden_pointer{
  cursor: pointer;
}
.guest_edit {
  em {
    font-style: normal;
  }
  font-size: 16px;
  margin-left: 1rem;
  width: 800px;
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-total{
  display: flex;
  justify-content: flex-end;
  margin-right: 40px;
  font-size: 16px;
}

.journeyImageModal {
  width: 700px !important;

  .ant-modal-body {
    text-align: center;
  }
}

.journeyListModal {
  .imageUpload {
    width: 200px;
    position: relative;

    .anticon-close-circle {
      font-size: 25px;
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }
  }
}

.taskCopyModal {
  .memName {
    text-align: left;
    margin-bottom: 20px;
  }

  .copyBtn {
    text-align: left;
    margin-top: 20px;
  }

  .ant-modal-body {
    text-align: center;
  }
  
  .table-container {
    position: relative;

    table {
      width: 100%;
      text-align: center;

      .top-title {
        width: 200px;
        height: 40px;
        color: #333;
        font-weight: 400;
        border: 1px solid #E7E7E7;
      }

      tr {
        position: relative;

        td {
          text-align: center;
          min-height: 40px;
          padding: 10px;
          color: #333;
          border: 1px solid #E7E7E7;
          font-size: 14px;
        }

        .date {
          width: 150px;
        }

        .place {
          width: 220px;
        }

        .topic {
          width: 350px;
        }

        .task {
          width: 150px;
        }
      }
    }

    .fixed {
      position: sticky;
      left: 0;
      background-color: #fff;
      z-index: 99;
    }
  }
}
