.question_item_wrap{
    margin-bottom: 30px;
    >p{
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
    >span{
        display: block;
        margin: 10px 0;
        color: #666;
    }
    .chart_view_wrap{
        border: 1px solid #eee;
        height: 400px !important;
        .chart_view{
            height: 300px !important;
            width: 600px;
            margin: 0 auto;
        }
        .tooltip_text_wrap{
            max-width: 300px !important;
            >div{
                >div{
                    &:first-of-type{
                        >div{
                            &:first-of-type{
                                white-space: normal !important;
                                word-break: break-all;
                            }
                        }
                        >span{
                            white-space: normal !important;
                            word-break: break-all;
                        }
                    }
                }
            }
        }
    }
    .title_wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .ant-radio-group{
            min-width: 160px;
            text-align: right;
        }
    }
}
