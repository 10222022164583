.sign_area_question{
        border: 1.5px dashed #cccccc;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 140px;
        .sign_area_box{
            height: 100%;
            width: 100%;
            display: flex;
            background-color:rgba(128, 128, 128, 0.2);
            align-items: center;
            div{
                width: 100%;
                text-align: center;
                user-select: none;
                color: #aaaaaa;
                font-size: 18px;
            }
        }
}
