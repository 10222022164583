.sponsors-item{
    display: flex;
    flex-direction: column;  

    .sponsors-item-title{
        display: flex;

        .sponsors-item-title-text{
            width: 80%;
            font-size: 16px;
            font-weight: bold;
        }
        .sponsors-item-switch{
            margin-right: 8px;
        }
    }
    .sponsors-item-sub-title{
        display: flex;
        margin-top: 6px;
        .sponsors-item-sub-title-text{
            width: 80%;
        }
    }
    .sponsors-no-tip{
        margin-top: 6px;
        font-size: 12px;
        color: #999999;
    }
}
