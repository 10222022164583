.core-data-all{
    padding: 0 40px;
    text-align: center;
    p{
        line-height: 1;
    }
    .tendency-num{
        font-weight: 700;
    }
}
.core-data-title{
    color: #999;
}
.data-hover-icon{
        margin-left: 5px;
        color: #000;
        font-size: 13px;
    }
.main-content-body{
    h2{
        font-weight: 600;
        font-size: 18px;
    }
}
.statistics-chart{
    position: relative;
    .echarts-p{
        width: 980px;
        position: absolute;
        left: 10px;
        z-index: 100;
    }
}
.echarts-module{
    height: 400px;
    width: 971px;
    margin-bottom: 20px;
}

.device-chart{
    height: 400px;
    width: 630px;
}

.device-chart-left{
    height: 400px;
    width: 550px;
}

.change-radio{
    float: right;
}

.china-map{
    width: 600px !important;
    height: 500px !important;
}

li{
    list-style: none;
}
.all-ul{
    width: 400px;
}
.row-ul{
    overflow: hidden;
    padding: 0;
    margin-top: 8px;
    li{
        float: left;
        width: 100px;
        text-align: center;
        color: #999;
    }
}

.row-ul-first{
    li{
        font-size: 15px;
        color: #000; 
    }
}
