
.page-title{
    display: flex;
    .page-left{
        display: flex;
        }
    .page-center{
        display: flex;
        flex: 1;
        text-align: center;
    }
    .page-right{
        Button{
            margin-right: 10px;
        }
    }
}


.preview-mobile-box{
    width: 100%;
    padding-top: 30px;
   background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .mobile-final{
        width: 400px;
        height:667px;
        position: relative;
        background-image: url('../../../../static/images/phone.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        .modal-content{
            width: 90%;
            height: 94%;
            transform: scale(1.02);
            margin-top:15px;
            position: relative;
            border-radius: 36px; 
            -moz-border-radius: 10px;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            background-color: black;
            .iframe-width{
                width: 100%;
                height: 100%;
                border: 1px soild #fff;
                // pointer-events: none;
            }
        }
        .phone-header{
            width: 40%;
            position: absolute;
            z-index: 1;
            left: 28%;
            top: 1%;
        }
    }
    .survey-answer{
        opacity: 0;
    }
}

.iframe-width{
    height: calc(100vh - 105px);
    width: 100%;
    // pointer-events: none;
}
