
.final-preview{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    width: 60%;
    .final-preview-btn{
        font-weight: 400;
        display: flex;
    }
}

