.upload-form{
    margin-bottom: 10px;
    position: relative;
}

.download{
    position: absolute;
    top: 5px;
    left: 100px;
    .operate-style{
        display: inline-block;
    }
    span{
        margin-left: 10px;
    }
}
