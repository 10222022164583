.solution-message-info{
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    
    .none-throw{
            margin-right: 5px;
            color: #E49201;
    }
    .throw-scusses{
        color: #03A113;
    }
}
.solution-message-content{
    // background-color: aqua;
    width: 85%;
    margin: 20px auto;
.description{
    margin-top: 5px;
    white-space: pre-wrap;        
    word-wrap: break-word;
    word-break:break-all;
    img{
        max-width: 100% !important;
        height: auto;
    }
}
.none-data{
    color: #ccc;
}
.solution-cover{
        width: 240px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-image {
          width: 240px;
          height: 135px;
          img{
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }

}
.solution-imgs{
    // margin-right: 5px;
    // .ant-image{
    //     width: 100%;
    //     .ant-image-img{
    //         height: 100px !important;
    //     }
    // }
    width: 240px;      
    height: 135px;      
    display: flex;      
    align-items: center;      
    justify-content: center;       
    .ant-image {        
        width: 240px;        
        height: 135px;        
        img{          
            object-fit: contain;          
            width: 100%;          
            height: 100%;        
        }
}
}
.docs-area{
    width: 95%;
    display: flex;
    justify-content: space-between;
    .docs-size{
        margin-top: 5px;
    }
}
.player-area{
    margin-top: 10px;
    width: 480px !important;
    height: 260px !important;
}  
.solution-desc{
    margin-top: 5px;
}
}
.solution-message-footer{
    border-top: 1px solid #eee;
    display: flex;
    justify-content: center;
    .solution-button-area{
        margin-top: 15px;
        .button-audit{
            margin-left: 30px;
        }
    }
 
}

