.logic_item{
    margin-top: 20px;
    margin-right: 10px;
    .ant-form-item{
        margin-bottom: 0;
    }
    .notice{
        color: red;
        font-size: 12px;
        padding-left: 40px;
        display: none;
    }
}
