
// .quick-enter-body{
    .main-table{

        .main-table-head{
            width: 100%;
            display: flex;
            padding: 16px 0;
            background: #fafafa;
            border-bottom: 1px solid #f0f0f0;

            &>span{
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                padding: 0 5px;
                box-sizing: border-box;
            }

            .main-table-name{
                width: 30%;
                //text-align: center;
            }
            .main-table-blank{
                width: 30%;
                text-align: center;
            }
            .main-table-onoff{
                width: 20%;
                text-align: center;
            }

            .main-table-setting{
                width: 20%;
                text-align: center;
            }

        } 
    }

    .main-quick-tr{
        display: flex !important;
        border-bottom: 1px solid #f0f0f0;
        padding: 16px 0;

        &:hover{
            background: #fafafa;
        }

        .main-table-title{
            width: 30%;
            //text-align: center;
            padding: 0 5px;
            box-sizing: border-box;
        }
        .main-table-blank{
            width: 30%;
            text-align: center;
            padding: 0 5px;
            box-sizing: border-box;
        }
        .main-table-switch{
            width: 20%;
            text-align: center;
            padding: 0 5px;
            box-sizing: border-box;
        }

        .main-table-operations{
            width: 20%;
            text-align: center;
            padding: 0 5px;
            box-sizing: border-box;
        }
    }
// }
