.site-calendar-demo-card {
  width: 300px;
  // border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.date_Btn {
  position: relative;

  Button {
    cursor: pointer;
  }

  .date_Picker {
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer !important;
  }

  .ant-picker-input {
    input {
      cursor: pointer !important;
    }
  }
}

.share-image {
  display: block;

  .share-image-area {
    display: block;
  }

  .ant-upload-list-text {
    display: none;
  }
}

.conferencelist_date {
  .ant-picker {
    width: 200px;
  }
}

.current-subtitle {
  em {
    font-style: normal;
  }

  font-size: 16px;
  margin-left: 1rem;
  width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conference_date_edit {
  label {
    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.conference_error {
  color: #ff4d4f;
}

.conferenctlist-copy-column-span {
  display: flex;
  gap: 4px;
  align-items: center;

  span {
    display: none;
    cursor: pointer;
  }

  &:hover {
    span {
      display: inline-block;
    }
  }
}
