.setting-button{
    padding-left:  0 !important;
}

.white_table_wrap{
    margin-top: 24px;
}

.add_white_personal{
    width: 100%;
    p{
        color: #ccc;
    }
    textarea{
        border-color: #fff;
    }
    .ant-input{
        padding: 0;
        
    }
}

