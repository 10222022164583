.haibao-lib{
    height: 450px;
    overflow-y: scroll;
    margin-left: 20px;
    .anchor{
        height: 240px;
        overflow: hidden;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        .haibao-head{
            margin-right: 40px;
            display: flex;
            justify-content: space-between;
            .haibao-title{
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
            }
            .haibao-more{
                height: 12px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
                cursor: pointer;
            }
        }
        .haibao-box{
            margin-right: 15px;
            margin-left: -10px;
           display: flex;
           flex-wrap: wrap;
           justify-content: space-around;
        }
       
        .haibao-item{
            width: 132px;
            height: 175px;
            display: inline-block;
            margin: 15px 10px ;
            position: relative;
            .haibao-icon{
                position: absolute;
                top: 8px;
                right: 7px;
                width: 50px;
                height: 20px;
            }
            .haibao-img{
                width: 132px;
                height: 175px;
                border-radius: 4px;
            }
            .haibao-fotter{
                width: 132px;
                height: 50px;
                background: #FFFFFF;
                opacity: 0.8;
                position: absolute;
                bottom: 0;
                z-index: 3;
                display: flex; 
                .haibao-qrcode{
                    margin: 6px 5px 6px 7px;
                }
                .haibao-elaborate{
                    margin-top: 6px;
                    .haibao-title{
                    width: 100%;
                    max-height: 16px;
                    font-size: 6px;
                    font-weight: 600;
                    color: #333333;
                    line-height: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    display: -webkit-box ;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    }
                    .haibao-description{
                        font-size: 5px;
                        color: #333333;
                        line-height: 12px;
                        width: 100%;
                        max-height:24px;
                        font-weight: 600;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        word-break: break-all;
                        display: -webkit-box ;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .haibao-action{
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: 4;
                border-radius: 5px;
                background: #FFFFFF;
                opacity: 0;
            }
            .action-btn{
                position: absolute;
                top: 35%;
                left: 20px;
                z-index: 5;
                opacity: 0;
            }
            &:hover{
                .haibao-action{
                    opacity: 0.6;
                }
                .action-btn{
                    opacity: 1;
                }
            }
        }
    }
    .four{
        height: 450px;
    }
    .haibao-box-four{
        height: 220px;
        overflow: hidden;
    }

}
.haibao-anchor{
margin-top: -10px;
margin-bottom: 20px;
.haibao-choice{
    span{
    margin: 0 20px;
    cursor: pointer;
    }
    .haibao-choice-item{
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
}
}
