
.operationEdit{
    display: inline-block;
    border:1px solid #ccc;
    overflow: hidden;
    cursor: pointer;
    p{
      float: left;
       width:50px;
       text-align: center;
       margin:0;
       &:nth-child(1){
           border-right: 1px solid #ccc;
       }
    }
}
.enable{
    color:#0f9d58;
    margin:0;
}
.disable{
    color:#f4b400;
    margin:0;
}
.personBox{
    position: relative;
}
.operationElement{
    position: absolute;
    width:120px;
    z-index: 1;
    background: #fff;
    text-align: center;
    box-shadow: 2px 2px 2px #ccc;
    cursor: pointer;
    top:24px;
    p{
        line-height: 35px;
        margin:0;
    }
}
#aId{
    color:#666;
}
.copyElement{
    display: inline-block;
    width:60px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #ccc;
    vertical-align:bottom;
    cursor: pointer;
}
