.fillForm-wrap{
    width: 100%;
    min-height: 400px;
    box-sizing: border-box;
    // padding: 40px 40px 0;
    // background-color: #fff;
    .main-content-box{
        .wrap{
            padding: 0 40px;
        }
        .F-head{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            padding-top: 40px;
            .left{
                font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: #333;
            }
            .right{
                button{
                    margin-left: 20px;
                }
            }
        }
        .fillDataStatus-wrap{
            color: red;
            font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 18px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 40px;
            border-bottom: 1px solid #eee;
            padding-bottom: 20px;
            p{
                margin-right: 60px;
            }
        }
        .chat-table{
            th,td{
                text-align: center;
            }
            .answerScore_input{
                width: 60px;
                border: 1px solid #ccc;
                color: #000;
                ::placeholder{
                    color: #000;
                }
            }
            .seeMore{
                color: #1890ff;
                cursor: pointer;
            }
            .checkBtn{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: nowrap;
                span{
                    margin-right: 10px;
                    color: #1890ff;
                    cursor: pointer;
                    &:last-of-type{
                        margin-right: 0px;
                    }
                }
            }
        }
        @media (min-width: 1200px){
            .ant-col-xl-4 {
                flex: 0 0 19.666667%;
                max-width: 19.666667%;
            }
        }
    }
}
.checkModal_wrap{
    p{
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 20px;
    }
    .btn{
        >div{
            >label{
                margin-right: 60px;
            }
        }
    }
    .con{
        margin-top: 30px;
        span{
            font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 10px;
            margin-top: 10px;
            float: left;
        }
    }
}

