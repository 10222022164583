.upload_img_question{
        border: 1.5px dashed #cccccc;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 104px;
        height: 104px;
        .upload_img_box{
            height: 100%;
            width: 100%;
            display: flex;
            background-color:rgba(128, 128, 128, 0.2);
            align-items: center;
            div{
                width: 100%;
                text-align: center;
                user-select: none;
                color: #666666;
            }
            .upload-title {
                font-size: 16px;
                margin-bottom: 10px;
                color: #000000;
            }
        }
    }
