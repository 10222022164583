.live-signup-page {
    .signup-toolbar {
        margin-bottom: 10px;
        .sub-tools {
            display: flex;
            justify-content: right;

            .refresh-btn {
                margin-left: 20px;
            }
        }
    }

    .table-components .main-content-body {
        padding-top: 0;
    }
}
