.sign-up-setting-equity {
    padding-left: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 40px;
}

.sign-up-setting-equity-input-cover {
    position: relative;
    padding-left: 100px;
}

.sign-up-setting-equity-input {
    border: 1px solid red !important;
    box-shadow: none !important;
}

.sign-up-setting-equity-label {
    width: 220px;
    display: inline-block;
    text-align: right;
    margin-right: 20px;
}

.sign-up-setting-equity-label-num {
    width: 240px;
    display: inline-block;
    text-align: right;
    margin-right: 20px;
}

.sign-up-setting-equity-input-error {
    position: absolute;
    left: 220px;
    bottom: -22px;
    color: red;
}

.sign-wp-setting-form {
    &-radio {
        .ant-radio-wrapper {
            align-items: center !important;
        }
    }
}

.sign-up-form-item-count {
    .ant-form-item-label {
        width: 160px !important;
        height: auto;
        white-space: normal;
    }

    .ant-form-item-label > label{
        height: auto;
        white-space: normal;
    }
}
