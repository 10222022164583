.member-detail {
    p {
        margin: 0;
    }

    .detail-header {
        .ant-form {
            width: 100%;
        }
    }

}

.memberModal {
    .ant-input-number {
        width: 100% !important;
    }
}



