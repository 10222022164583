.conference-live-form {
    .share-image-area {
        margin-bottom: 10px;
    }

    .ant-upload-list {
        display: none;
    }

    .react-editor {
        position: relative;
        max-height: 350px !important;
        padding: 0;

        z-index .ql-toolbar {
            position: sticky;
            background-color: #fff !important;
            top: 0;
            z-index: 1;
            border: 1px solid #ccc;
            text-align: left;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }
}

.personal-auth,
.organization-auth {
    .ant-checkbox-group-item {
        width: 130;
        font-weight: 600;
    }
}

.uploadReplay {
    .ant-upload-list-item-card-actions {
        display: none;
    }
}

.videoBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 500px;
}

.current-subtitle {
    em {
        font-style: normal;
    }

    font-size: 16px;
    margin-left: 1rem;
    width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.live-start-time {
    .ant-form-item-control-input-content {
        display: flex;

        .time-input-box {
            width: 30%;

            .time-input {
                width: 100%;
            }
        }
    }

    .time-unit {
        margin: 5px 5px;
    }
}

.copy-live-name {
    position: relative;
    display: flex;
    width: 742px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9d9d9;

    .live-name {
        word-break: break-all;
        padding: 4px 40px 4px 11px;
    }

    .ant-btn {
        position: absolute;
        border: none;
        border-left: 1px solid #d9d9d9;
        right: 0px;
        width: 30px;
        height: 100% !important;
    }
}

.registration-wrap {
    display: flex;
    flex-direction: row;
    margin-left: 110px;

    .registration-left {
        width: 260px;
        margin: 16px 16px 16px 0;
        border: 1px solid rgba(0, 0, 0, .06);
        border-radius: 2px;
        padding: 10px;

        .registration-left-info-title {
            font-weight: bold;
            font-size: 16px;
        }

        .registration-left-checkbox {
            .ant-checkbox-wrapper {
                margin-left: 30px;

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }

        .registration-left-add {
            margin-top: 20px;

            .registration-left-add-title {
                font-weight: bold;
                font-size: 16px;
            }

            .registration-left-add-desc {
                margin-bottom: 10px;
            }

            .ant-space {
                width: 100%;
            }
        }
    }

    .registration-right {
        flex: 1;
        margin: 16px 0 16px 0;
        border: 1px solid rgba(0, 0, 0, .06);
        border-radius: 2px;
        padding: 10px;

        .registration-right-desc-title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
        }

        .registration-right-drag-form {
            .registration-right-drag-form-title {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
    }
}

// 拖拽容器样式
.drag-form-item {
    margin-bottom: 20px;
    border: 1px dashed #1a73e8;
    padding: 5px 10px;

    .registration-right-drag-form-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-form-item {
            margin-bottom: 0;
        }

        .head-left {
            display: flex;
            align-items: center;

            .question-title {
                margin-right: 10px;
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }

        .head-right {}
    }

    .registration-question-content {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .registration-question-index {
            display: inline-block;
            width: 24px;
            font-size: 16px;
        }

        .ant-form-item {
            flex: 1;
            margin-bottom: 0;
        }
    }

    .registration-right-drag-option-head {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .ant-form-item {
            margin-bottom: 0 !important;
            width: 100%;
        }

        .ant-checkbox-wrapper {
            margin-right: 8px;
        }
    }
}

.live-limit-page {
    .page-form-foot-operations {
        width: calc(100vw - 248px);
        background-color: #FFFFFF;
        height: 60px;
        position: fixed;
        bottom: 0;
        right: 24px;
        z-index: 999;
        border-top: 1px solid #f0f0f0;
        margin-bottom: 0;
        padding: 10px;
    }

    .switch-item {
        .ant-form-item-label {
            width: 110px;
        }
    }
}
