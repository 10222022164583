.ratingmod6{
    line-height: 20px;
    visibility: visible;
    font-size: 15px;
    color: #333333;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    dl{
        text-align: left;
        line-height: 20px;
        visibility: visible;
        color: #333333;
        margin: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 8px 10px 12px 0px;
        border-bottom: 0;
    }
    .onscore{

        text-align: left;
        line-height: 20px;
        visibility: visible;
        font-size: 15px;
        color: #333333;
        margin: 0;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        white-space: nowrap;
        border: none;
        align-items: center;
        clear: both;
        margin-top: 0;
        display: flex;
        height: 40px !important;
        background: rgba(23, 109, 242,0.1);
        color: #000;
        box-shadow: 0px 0px 20px 0px rgba(146,146,146,0.13);
        // background-image: linear-gradient(to right,#fff,#0095ff);
        padding: 0 !important;
        border-top: 0 !important;
        flex-wrap: nowrap !important;
        justify-content: flex-end;
        margin-right: 10px;
        li{
            margin: 0;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            list-style: none;
            background: none !important;
            cursor: pointer;
            margin-bottom: 0;
            display: inline-block;
            vertical-align: top;
            list-style-type: none;
            font-size: 14px;
            color: #313233;
            border-radius: 0;
            text-align: center;
            width: 20px;
            height: 40px;
            line-height: 40px !important;
            flex: 1 1 auto !important;
            padding: 0;
            border: 1px solid #e3e3e3;
            border-right: 0;
            border-left: 1px solid #e3e3e3;
        }
    }
}
