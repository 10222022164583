.online-date {
  .date-limit {
    margin-left: 20px;
    font-size: 14px;
    color: #aaa;
  }

  .online-date-choice {
    margin-bottom: 18px;

    .ant-picker {
      height: 32px;
      display: inline-block;
    }

    .ant-picker-range {
      display: inline-flex;
    }
  }

  .content-block-title {
    margin-bottom: 50px;
  }


  .online-project-choice {
    margin-bottom: 18px;

    .ant-select {
      width: 280px !important;
      margin-right: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }

}

.online-chart {
  width: 100%;
  height: 400px;
}

.project-chart {
  width: 100%;
  height: 600px;
}

.online-date-title {
  overflow: hidden;
  font-size: 18px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
