.filter-form-buttons {
  text-align: right;
  margin-bottom: 24px;
}
.operate-red-style {
  color: red;
  margin-right: 10px;
  cursor: pointer;
}
.reviewedAtClass{
  color: #bbbbbb;
  margin: 0;
  padding: 0;
}
.reviewedNameClass{
  margin: 0;
  padding: 0;
}
.ToolRejectReason{
  color: #000000;
}

