// 会议地点下拉框样式

.place-content {
  display: flex;
  justify-content: space-between;

  .place-title-name {
      flex: 1;
      white-space: break-spaces;
      padding-right: 8px;
  }

  .place-right-option {
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .right-icon-edit,
      .right-icon-delete {
          flex: 1;
      }
  }
}
