.new-survey{
    margin-top: 5px;
    margin-bottom: 15px;
}
.antd_btn_padding{
    .ant-btn.ant-btn-link{
        padding: 4px 5px;
    }
}

.survery-name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box ;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
