.wall-newspaper-page {
    display: flex;

    .wallNewspaper-form-solution {
        width: calc(100vw - 716px);

        .ant-form-item .ant-form-item-label {
            .wall-tip {
                padding-left: 20px;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }

    .wall-newspaper-wrap {
        .wall-newspaper-preview-wrap {
            margin-left: 20px;
            border: 1px solid #EEE;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);

            .wall-newspaper-preview {
                position: relative;
                width: 448px;
                height: 1120px;
                padding: 65px 24px 0;
                background-position: left top;
                background-size: cover;
    
                .wall-newspaper-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 18px;
    
                    .wall-newspaper-logo {
                        width: 75px;
                        height: 75px;
                        margin-right: 19px;
                    }
    
                    .wall-newspaper-title {
                        font-weight: bold;
                        font-size: 23px;
                        line-height: 25px;
                        color: #363636;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
    
                .wall-newspaper-code {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 23px;
    
                    .wall-newspaper-left {
                        flex: 1;
                        display: flex;
                        height: min-content;
    
                        .wall-newspaper-line {
                            width: 4px;
                            height: auto;
                            background: linear-gradient(134deg, #59049C 0%, #AC21AE 51%, #DA5888 100%);
                            border-radius: 20px 20px 20px 20px;
                            margin-right: 12px;
                        }
    
                        .wall-newspaper-name {
                            flex: 1;
                            font-weight: bold;
                            font-size: 19px;
                            line-height: 25px;
                            color: #363636;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
    
                    .wall-newspaper-qrcode {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-left: 44px;
    
                        .wall-qrcode {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 66px;
                            height: 66px;
                            padding: 1px;
                            background-color: #ffffff;
                            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
                            border-radius: 2px 2px 2px 2px;
                        }
    
                        span {
                            font-size: 12px;
                            color: #363636;
                            margin-top: 4px;
                        }
                    }
                }
    
                .wall-newspaper-item {
                    margin-bottom: 10px;
    
                    .wall-newspaper-tag {
                        font-weight: bold;
                        font-size: 16px;
                        color: #FFFFFF;
                        width: fit-content;
                        padding: 0 14px;
                        height: 29px;
                        line-height: 29px;
                        background: linear-gradient(134deg, #59049C 0%, #AC21AE 51%, #DA5888 100%);
                        border-radius: 20px 20px 20px 20px;
                        margin-bottom: 9px;
                    }
    
                    .wall-newspaper-tag-content {
                        font-size: 14px;
                        color: #363636;
                        line-height: 20px;
                    }

                    p {
                        line-height: 20px;
                        color: #363636;
                    }
                }
    
                .poster-solution-image-wrap {
                    width: 100%;
                    text-align: center;
    
                    .poster-solution-image {
                        width: 370px;
                        height: 208px;
                        margin: 10px 0 34px;
                    }
                }
    
                .wall-newspaper-footer-content {
                    position: absolute;
                    bottom: 73px;
                    left: 50%;
                    transform: translateX(-50%);
    
                    .wall-newspaper-footer-code {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
        
                        .wall-footer-qrcode {
                            width: 65px;
                            height: 65px;
                        }
        
                        span {
                            font-weight: bold;
                            font-size: 12px;
                            color: #363636;
                            margin-top: 6px;
                        }
                    }
        
                    .poster-footer-logo-wrap {
                        text-align: center;
                        margin-top: 36px;
        
                        .poster-footer-logo {
                            width: 120px;
                            height: 44px;
                        }
                    }
                }
            }
        }

        .wall-download-btn {
            text-align: center;
            margin-top: 20px;
        }
    }
}
