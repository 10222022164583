.cooperate-unit{
    .ant-descriptions-item{
        padding: 0;
    }
    .ant-descriptions-item-content{
        display: block !important;
    }
}

.mock-block{
    color: #999;
}

.live-teather{
    .ant-tag{
        span{
            margin-left: 5px;
        }
    }
}

.ql-container{
    min-height: 120px;
}
