.stage-mask-modal {
    .ant-modal-body {
        padding-top: 0;
    }

    .stage-task-tips {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }
}

.add-stage-mask {

}

.row-dragging {
    position: relative;
    z-index: 1000;
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}
