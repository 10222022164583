.header {
  display: flex;
  background-color: #1a73e8;
  height: 50px;
  line-height: 50px;
  padding: 0 24px;
  color: white;

  box-shadow: 0 2px 4px rgba(0, 0, 0, .075);

  .brand {
    display: inline-block;
  }

  .status-bar {
    flex: 1;
    text-align: right;

    .entry {
      margin: 0 4px;
      height: 50px;
      line-height: 50px;
    }

    .entry-messages {
      font-size: 16px;
    }

    .entry-my {

    }
  }
}


#module-mini-menu-trigger {
  font-size: 20px;
}

#platform-title {
  color: white;
   font-size: 20px;
   margin-left: 10px;
   //vertical-align: middle;
 }


.my-menu {
  .username {
    border-bottom: 1px solid #eee;
    padding: 4px 10px;
  }

  .icon-my {
    margin-right: 8px;
    color: #888;
  }
}

