.custom-icon{
    position: relative;
    .custom-icon-helper{
        position: absolute;
        top: 35px;
        left: 90px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
    }
    .upload-area{
        width: 80px;
        height: 80px;
        background: #F5F6F8;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .upload-icon{
            width: 56px;
            text-align: center;
        }
        .upload-image{
            width: 100%;
            height: 100%;
        }
        .del-upload-area{
            position: absolute;
            z-index: 999;
            width: 14px;
            height: 14px;
            top: -5px;
            right: -5px;
        }
    }
}
