.agenda-preview-container {
  margin: 0 auto;
  width: 750px;

  .conference-area {
    font-size: 28px;
    text-align: center;
    line-height: 1.5em;
    font-weight: 600;
    color: rgba(255, 255, 255, 85%);
  }

  .conference-place {
    font-size: 28px;
    text-align: center;
    line-height: 2em;
    margin-bottom: 30px;
    font-weight: 600;
    color: rgba(255, 255, 255, 85%);

    svg {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }

  .agenda-preview-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .conference-name {
      display: flex;
      justify-content: start;
      align-items: center;
      flex: 1;
      font-size: 20px;
      //font-weight: 600;
    }

    .toolbox {
      display: flex;
      justify-content: end;
      align-items: center;
    }

  }

  .agenda-preview-content {
    font-size: 16px;
    background-color: #0327ff;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;

    h1 {
      display: block;
      position: relative;
      //border-radius: 30px 30px 0 0;
      text-align: center;
      font-size: 32px;
      color: white;
      line-height: 1.5em;
      //height: 3em;
      //margin-bottom: 20px;
      // padding: 80px 16px 30px;
      font-weight: 600;


      .head-img {
        width: 100%;
        height: auto;
      }

      .head-title {
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 9;
        padding: 80px 16px 30px;
      }
    }

    h2 {
      text-align: center;
      font-size: 24px;
      line-height: 2em;
      background: #FCDD9F;
      border-radius: 12px;
      width: max-content;
      margin: 0 auto 28px;
      padding: 0 20px;
      color: #BA0031;
    }

    table {
      width: 100%;
      line-height: initial;
      margin-bottom: 50px;
      font-size: 16px;

      th, td {

        &.time {
          width: 110px;
          font-size: 14px;
        }

        &.subject {
          font-weight: 600;
        }

        &.task {
          width: 75px;
        }

        &.guest {
          width: 100px;
        }

        &.guest-info {

        }

        &.centered {
          text-align: center;
        }
      }

      .conference-hall,
      .conference-sub-hall,
      .agenda {
        position: relative;

        .conf-display-hidden {
          position: absolute;
          left: -100px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          font-weight: normal;
          color: #fa8c16;
        }

        .agenda-display-hidden {
          position: absolute;
          left: -250px;
          top: 12px;
          transform: translateY(-50%);
          font-size: 12px;
          font-weight: normal;
          color: #fa8c16;
        }

        .guests-display-hidden {
          position: absolute;
          right: -150px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          color: #fa8c16;
        }
      }

      > td {
        padding: 4px 8px;
      }

      .conference-title-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: max-content;
        text-align: left;
        padding: 0 19px;

        .wrap-left {
          position: absolute;
          display: inline-block;
          width: 7px;
          height: 100%;
          background: #FCDD9F;
        }

        .conference-hall {
          font-size: 32px;
          font-weight: 600;
          color: white;
          margin-bottom: 0;
          text-align: center;
        }

        .conference-sub-hall {
          font-size: 28px;
          text-align: center;
          font-weight: 600;
          color: white;
          margin-bottom: 0;
          margin-top: 10px;

          .sub-hall-title {

          }
        }

        .hall-text {
        }

        .hall-place {
          font-size: .75em;
          margin-top: .5em;
          font-weight: normal;
          color: rgba(255, 255, 255, 80%);

          svg {
            margin-right: 8px;
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
          }
        }

      }

      .conference-hall-agenda-summary {
        background-color: #bae7ff;
        //font-weight: 600;
        //color: white;
        border-bottom: 1px solid white;

        td {
          padding: 4px 10px;
          line-height: 1.5em;

          .conf-extra {
            //padding: 10px;
            //background-color: #FAFAFA;
            //border: 1px solid #F0F0F0;

            .agenda-summary {
              text-align: justify;
            }

            > div:not(:last-child) {
              margin-bottom: 8px;
            }

            .directors {

            }

            .implementers {
              margin-left: 24px;
            }

            .sponsor-info {
              margin-left: 24px;
            }
          }

        }
      }

      .agenda-th {
        background-color: #DDD;
        line-height: 2em;
        border-bottom: 1px solid #CCC;

        th {
          padding: 4px;
        }
      }

      .agenda-guest-list {
        background-color: #bae7ff;
        line-height: 1.5em;
        border-bottom: 1px solid white;
        //text-align: center;
        font-weight: 600;

        td {
          padding: 4px 10px;
          line-height: 1.5em;
        }
      }

      .agenda {
        // border-bottom: 1px solid #CCC;

        td {
          padding: 4px;
          line-height: 1.5em;
        }

        .subject {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0;
        }

        .guest-name {
          font-size: 24px;
          font-weight: 600;
        }

        .guest-info {
          font-size: 20px;
          color: #888;
        }
      }

      .no-padding {
        padding: 0 !important;
      }

      table {
        margin-bottom: 0;

        tr {
          &:not(:last-child) {
            border-bottom: 1px solid #EEE;
          }

          td:first-child {
            width: 100px;
            text-align: left;
          }

        }

        td {
          padding: 4px;
          line-height: 1.5em;
        }
      }

    }

    .code-list {
      display: flex;
      justify-content: space-around;
      padding: 50px 30px;

      .qrcode-box {
        width: 230px;
      }

      .qrcode {
        width: 158px;
        height: 158px;
        margin: 0 auto 10px;
        border: 4px solid white;
        border-radius: 4px;

        canvas {
          display: block;
          margin-bottom: 10px;
        }

        img {
          display: block;
          width: 150px;
          height: 150px;
          margin-bottom: 10px;
        }
      }

      p {
        color: white;
        font-size: 18px;
        line-height: 1.5em;
        text-align: center;
        margin-bottom: 0;
        opacity: 0.8;
      }

    }

    .foot {
      padding: 50px 0 30px;
      //border-radius: 0 0 10px 10px;
      font-size: 26px;
      text-align: center;
      color: white;
      opacity: 0.8;
    }

  }

  .agenda-preview-foot {
  }

  .timeline-wrap {
    width: 710px;
    margin: 20px auto 0;
    background: #FFFFFF;
    border-radius: 20px 20px 20px 20px;
    padding: 25px 10px 0 10px;

    .ant-timeline-item:last-of-type {
      padding-bottom: 0 !important;
    }

    .ant-timeline-item-label {
      width: 120px !important;
      font-size: 18px;
      color: #666;
    }

    .ant-timeline-item-tail {
      left: 133px !important;
      border-left-width: 4px;
    }

    .ant-timeline-item-head {
      left: 132px !important;
      width: 16px;
      height: 16px;
      border-width: 4px;
    }

    .ant-timeline-item-content {
      left: 132px !important;
      width: calc(100% - 160px) !important;
      top: -10px
    }
  }

}

