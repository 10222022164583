.survey_charts_wrap{
    min-height: 100%;
    min-width: 1200px;
    .main_content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 100%;
        background-color: #fff;
        .left{
            width: 150px !important;
            height: 100%;
            padding: 24px;
            box-sizing: border-box;
            p{
                text-align: center;
                margin-top: 24px;
            }
            p.active{
                color: #1890ff;
            }
        }
        .right{
            flex: 1;
            background-color: #f0f2f5;
            padding: 30px 24px 0;
            box-sizing: border-box;
            // min-height: 80vh;
            h2{
                font-size: 16px;
                position: relative;
                padding-left: 10px;
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    background-color: #1890ff;
                }
            }
            .head{
                margin-bottom: 24px;
                >div{
                    background-color: #fff;
                    padding: 30px 0;
                }
                .font_blod{
                    font-weight: 700;
                    font-size: 20px;
                }
                .data_wrap{
                    text-align: center;
                }
                .time_wrap{
                    span{
                        font-weight: 700;
                        font-size: 20px;
                    }
                }
            }
            .data_content{
                >div{
                    &:first-of-type{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
                .data_content_btn{
                    border-color: #1890ff;
                    color: #1890ff;
                    border-radius: 4px;
                }
                .data_content_main{
                    min-height: 400px;
                    background-color: #fff;
                    width: 100%;
                    padding: 24px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
