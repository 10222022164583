.consultations-content-body {
  margin-bottom: 24px;

  h2 {
    font-weight: 600;
    font-size: 18px;
  }
}

.consultations-form-subtitle {
  padding: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #888;
  align-items: center;
}

.consultations-content-title {
  flex: 8;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
}

.consultations-content-title-right {
  padding-right: 24px;
  text-align: right;
  flex: 2;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #eee;
}

.consultations-table-caption {
  display: flex;
}

.consultations-table {
  margin-top: 20px;
  padding-left: 2%;
  padding-right: 2%;
}
.consultations-page-form{
  color: black;
}

