.member-detail {
    p {
        margin: 0;
    }

    .detail-header {
        .ant-form {
            width: 100%;
        }
    }

    .member-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .member-left {
            .ant-btn {
                margin-right: 20px;
            }
        }
    }

    .edit-btn {
        padding-left: 0;
    }

}

.memberModal {
    .ant-input-number {
        width: 100% !important;
    }
        
    .ant-tag{
        font-size: 16px !important;
        padding: 6px 24px;
        border-radius: 4px;
    }

    .tagTips{
        padding-top: 10px;
        color: #f92b2b;
    }
}



