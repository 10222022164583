.haibao_modal{
    .ant-modal-body{
        padding:5px 0 0 0;
    }
    .haibaoBox{
        height: 580px;
        .ant-tabs-tab,.ant-tabs-tab-active{
            font-size: 18px;
            line-height: 25px; 
        }
        .ant-tabs-tab{
            font-weight: 400;
        }
        .ant-tabs-tab-active{
            font-weight: 600;
            color: #005FED;
        }
    }
    .save-btn{
        margin-right: 20px ;
    }
}
