.choice-action{
    margin-top: 10px;
    img{
        width: 17px;
        height: 17px;
    }
}
.question-title{
    display: flex;
    // align-items: center;
    font-size: 16px;
    .question-id{
        display: flex;
        align-items: flex-start;
        margin-top: 10px;
    }
}

.required{
    color: red;
    font-size:20px;
}
.question-title-area{
    overflow-y: hidden;
    font-size: 22px !important;
    width: 98%;
    .ql-toolbar.ql-snow {
        width: 110px;
        margin-bottom: 10px;
    }
    .ql-container{
        margin-left: 10px;
        font-size: 22px !important;
        min-height: 22px !important;
        border:none;
    }
    .ql-toolbar{
        display: none;
        position:absolute;
        background:#fff;
        padding:0 2px;
        margin-left: 10px;
        z-index: 9;
        top: -5px;
    }
    
        &:hover {
            .ql-container.ql-snow,.ql-container{
                border:1px dashed #1a73e8 ;
            }
            
       }
    
}
.question-index{
    font-size: 22px !important;
}
.options-item{
    display: flex;
    width: 100%;
    cursor:default​​;
    span:nth-child(2){
        display: flex;
        align-items: center;
    }


    TextArea{
        width: 50vw;
        font-size: 18px;
        overflow-y:hidden
    }
    &:hover .select-opion-action{
            display: flex;
    }
}
.select-opion-action{
    display: none;
    span{
        margin-left: 20px;
        margin-top: 10px;
    }
    .drop-opinion{
        color: #000;
        cursor: pointer;
        margin-top: 20px;
    }
    .del-opinion{
        color: #000;
    }
}
.more-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.other-option{
    margin-top: 10px;
}
.add-other{
    display: flex;
    .add-other-area{
        width: 100%;
    }
}
// .question-other-opintion,.singl-question-other-opintion{
//     width: 50vw;
//         .question-other-del{
//         display: none;
//         color: #000 !important;
//         margin-left: 20px;
//         margin-top: 10px;
//     }
//     display: flex;
//     .question-other-text{
//         min-width: 50px;
//         margin-left: 10px;
//         font-size: 18px;
//         display: flex;
//         border: 1px solid  transparent;
//         color: #000 !important;

//         &:hover{
//             border: 1px dashed  #1a73e8;
//         }
//         TextArea{
//             border: none;
//             font-size: 18px;
//         }
       
//     }
//     &:hover  .question-other-del{
//         display: inline-block;

// }
// .fill_area{
//     display: flex;
//     flex: 1;
//     align-items: flex-end;
//     margin-bottom: 5px;
//     color: #000 !important;
// }
// }
.icon-width{
    margin-right: 10px !important;
}
.more-opinion{
    width: 80%;
    margin: 5px;
}

.option-item{
    overflow-y: hidden;
    width: 300px;
    display: none;
    &:hover{
        border: 1px dashed  #1a73e8 !important;
    }
}

.singl-question-other-opintion{
    margin-top: -5px !important;
}

.question-division{
    margin-top: 10px;
    margin-right: 10px;
    color: #D3D3D3;
    opacity: 0.5;
    padding: 0 10px; 
}

.select-question-area{
    height: 200px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    margin-top: 10px;
}
.sort-all{
    user-select: none;
}

