.opert-box{
    padding:40px;
    padding-top: 20px;
    display: flex;
 .opert-left{
    width: 308px !important;
    height: 462px;
    border-radius: 9px;
    overflow: hidden;
    position: relative;
    margin-right:50px;
    .opert-left-box{    
        width: 308px;
        height: 462px;
        border-radius: 9px;
        position: relative;
        margin-right:50px;
        .haibao-icon{
            position: absolute;
            top:19px;
            right:16px;
            width: 100px;
            height: 40px;
            z-index: 3;
        }
        .haibao-img{
            position: absolute;
            width: 308px;
            height: 462px;
            z-index: 2;
            background-repeat: no-repeat !important;
            background-size: cover !important;
        }
        .haibao-fotter{
            width: 308px;
            height: 117px;
            background: #FFFFFF;
            opacity: 0.8;
            position: absolute;
            bottom: 0;
            z-index: 3;
            display: flex;
            .haibao-qrcode{
                margin: 14px 12px 14px 16px;
                width: 89px;
                height: 89px;
            }
            .haibao-elaborate{
                margin-top: 14px;
                .haibao-title{
                    width: 100%;
                    //max-height: 40px;
                    font-size: 14px;
                    color: #333;
                    font-weight: 600;
                    line-height: 20px;
                    //text-overflow: ellipsis;
                    //overflow: hidden;
                    //word-break: break-all;
                    //display: -webkit-box ;
                    //-webkit-line-clamp: 2;
                    //-webkit-box-orient: vertical;
                }
                .haibao-description{
                    font-size: 12px;
                    font-weight: 400;
                    width: 100%;
                    max-height: 48px;
                    color: #333333;
                    line-height: 16px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    display: -webkit-box ;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
 }   

    .opert-right{
        flex: 1;
        .haibao-custom{
            padding: 0;
        }
        .ant-upload-list{
            display: none;
        }
        .upload-haibao{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .upload-reset{
                cursor: pointer;
            }
            .upload-segmentation{
                color: #ccc;
                margin: 0 8px;
                position: relative;
                top: -1.8px;
            }
        }
        .logo-switch{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }
    }
    .ant-form-item-label>label,.set-size{
        font-size: 16px;
        font-weight: 500;
    }
}
