.bankInfo{
    position: relative;
    .editButton{
        position: absolute;
        right: 12px;
        top: 76px;
    }
    .placeholderColor{
        color: #888;
    }
}
