.case-detail {
    .detail-header {
        span {
            margin-right: 30px;
        }
    }

    .ant-tabs-nav-list {
        margin-left: 0;
    }

    .ant-form-item-control {
        // margin-top: 5px;
    }

    .base-cover-wrap {
        display: flex;

        .base-cover {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 360px;
            height: 200px;

            .ant-image {
                width: 360px;
                height: 200px;

                img {
                    object-fit: contain;
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .cover-info {
            margin-left: 20px;
        }
    }

    .base-process {
        background-color: rgba(0, 0, 0, 0.0196078431372549);
        border-radius: 3px;
        line-height: 33px;
        margin-bottom: 10px;
        padding: 0 20px;
    }

    .case-doc {
        //display: flex;
        //align-items: center;
        //
        //.docIcon {
        //    margin-right: 5px;
        //    margin-top: 3px;
        //    line-height: 16px;
        //}
    }

    .case-images {
        display: flex;
        flex-wrap: wrap;

        .case-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 105px;

            .ant-image {
                width: 150px;
                height: 105px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .case-detail-footer {
        text-align: center;
    }

    .contact-info, .base-info {
        .ant-form-item-label {
            width: 150px !important;
        }

        .contact-process {
            div {
                margin-top: 5px;
                background-color: rgba(0, 0, 0, 0.0196078431372549);
                border-radius: 3px;
                height: 33px;
                line-height: 33px;
                margin-bottom: 10px;
                padding-left: 20px;

                span {
                    display: inline-block;
                    width: 100px;
                }
            }
        }
    }

    .attach-list-cell {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        row-gap: 4px;

        .attach-item {
            display: flex;
            flex-shrink: 0;
            align-items: center;

            .ant-btn-link {
                padding: 0;
                margin-left: 10px;
            }

            &:not(:last-child) {
                margin-right: 10px;
                padding-right: 10px;
                border-right: 1px solid #ccc;
            }
        }

        .attach-icon {
            display: inline-flex;
            width: 16px;
            height: 16px;
            margin-right: 4px;

            svg {
                fill: #888;
            }
        }
    }

    .cert-list {
        background-color: #fff7e6;
        padding: 8px;
        border-radius: 4px;
        margin-top: 8px;

        .cert-title {
            font-weight: 600;
        }

        .cert-item {
            display: flex;

            &:hover {
                background-color: white;
            }

            span {
                flex: 1
            }
        }
    }

    .fixed-foot-toolbar {
        position: fixed;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 10px 24px 10px 244px;
        background-color: white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        z-index: 100;
    }

}
