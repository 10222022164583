.task-list {
  padding-left: 16px;
  margin-bottom: 0;

  li {
    list-style: disc;
    margin-bottom: .5em;

    .task-time {
      color: #888;
      font-size: .8em;
    }
  }

}

.expert-level {

}

.sub-form-fields {
  padding: 16px;
  background-color: #F4F4F4;
  border-radius: 0 8px 8px 8px;
  margin-bottom: 24px;
}

.link-btn-operations {
  &.ant-btn-link {
    color: #666;
    &:hover {
      color: #333;
    }
  }
  &.ant-btn > .anticon + span {
    margin-left: 4px;
  }
}
