#root {
  height: 100%;
}

.App {
  height: 100%;
}

.login-div {
  background-color: #1a68d3;
  background-image: radial-gradient(ellipse at bottom, #1a68d3 5%, transparent 60%),
  linear-gradient(136deg, transparent, #71c7ee 290%),
  linear-gradient(115deg, #9d9af1, transparent 40%),
  linear-gradient(180deg, transparent 0, #1a68d3 70%),
  radial-gradient(ellipse at -70% -180%, transparent 80%, #71c7ee 0),
  radial-gradient(ellipse at bottom, #1a68d3 40%, transparent 80%),
  radial-gradient(ellipse at 5% 340%, transparent 80%, #bfc2f4 0);

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .login-content + p {
    color: #c2daf9;
    margin-top: 8px;
  }
}

.login-content {
  width: 420px;
  height: 320px;
  background-color: #fff;

  .ant-card-head {
    border-bottom: 0;
    text-align: center;
    font-size: 22px !important;
    font-weight: 600;
    color: #4185f4;
  }

  .site-form-item-icon {
    color: #c7c7cc;
  }
}

.remember-forget {
  margin-top: 20px;
  overflow: hidden;

  .ant-checkbox-wrapper {
    float: left;
  }

  a {
    float: right;
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 1) inset !important;
  -webkit-background-clip: text;
}

.forget-password-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.forget-password-content {
  width: 550px;
  height: 350px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
  }

  h2 + span {
    display: inline-block;
    font-size: 16px;
    // margin-bottom: 20px;
  }
}

.hidden-show-phone {
  position: fixed;
  bottom: -9999px;
  z-index: -100;
  width: 0 !important;
  height: 0;
}
