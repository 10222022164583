.color {
    width: 18px;
    height: 2px;
    margin-left: 6px;
    margin-top: -2px;
    border-radius: 2px;
}
.swatch {
    padding: 3px 25px;
    padding-bottom:5px;
    width: 80px;
    box-sizing: border-box;
    margin-top: 1px;
    background: #f2f2f2;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0;0;0;.1);
    display: inline-block;
    cursor: pointer;
    p{
        width: 30px;
        text-align: center;
        font-weight: 600;
        padding: 0;
        margin: 0;
    }
    
}
.popover {
    position: absolute;
    z-index: 2;
    background-color: #fff;
}
.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}
.color-picker{
        input{
            width: 100% !important;
        }
}
