.conflicting-guests {
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;

  .guest {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .conflicting-agenda {
    margin: 8px 0;
    background-color: #F0F0F0;
    border-radius: 4px;
    padding: 4px 8px;

    .agenda-task {
      span {
        margin-right: 16px;
      }
    }
  }

  .conflict {
    color: #fa8c16;
  }
  .conflictRed {
    color: red;
  }
}

.agenda-form {
  width: 1000px;
  margin: 0 auto;
}

.add-guest-item {
  position: relative;
  z-index: 99999;
  margin-bottom: 10px;
  padding: 6px 8px;
  background-color: #F4F4F4;
  border-radius: 4px;

  .no {
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    left: -36px;
    top: 50%;
    font-size: 9px;
    background-color: #f4f4f4;
    text-align: center;
    line-height: 28px;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  .agenda-task-count {
    width: 80px;
  }

  .ant-space:first-child {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .ant-space:last-child {
    .ant-form-item {
      margin-bottom: 0;
    }

    .anticon {
      cursor: pointer;
    }
  }
}
