.main-content-box.cases-table-content {
    padding: 20px;

    .cases-table-header {
        display: flex;
        align-items: center;

        input {
            margin-right: 16px;
        }

        .ant-select {
            margin-right: 16px;
        }

        span {
            flex-shrink: 0;
        }

        .assignment-buttons {
            margin-left: 16px;
            & button:first-of-type {
                margin-right: 12px;
            }
        }
    }

    .lasttime-span {
        color: #1089da;
        cursor: pointer;
    }
    .redSpan{
        color: #f5222d;
        cursor: pointer;
    }

    .cases-table-header-button {
        display: flex;
        gap: 20px;
        margin-top: 16px;
    }
}

.teamMemberHeader{
    width: 940px;
    background: #F5F6F8;
    border-radius: 8px;
    margin: 0 auto 20px;
    padding: 10px;
    box-sizing: border-box;
  
    >h2{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
    }
  
    p{
      font-size: 16px;
      color: #999999;
      margin-bottom: 8px;
  
      >span{
        color: #000000;
      }
    }
  
    >div{
      display: flex;
      align-items: center;
  
      >p{
        margin-right: 50px;
        margin-bottom: 0;
      }
    }
  }

.cases-table-casesHeader {
    display: flex;
    align-items: center;
    gap: 12px;

    .ant-form {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .form-flex-wrapper {
            display: flex;
            flex-direction: row;
        }

        .cases-table-casesHeader-count {
            display: flex;
            gap: 20px;
            align-items: center;
            margin-top: 10px;
        }
    }
}
.cases-table-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 300px;
}

.modal-fraction-score {
    margin: 0 0 12px 82px;
}

.modal-flex-div {
    display: flex;
    align-items: center;
    margin-left: 40px;

    span {
        flex-shrink: 0;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.ant-pagination.my-pagintation-class {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

