.projectName {
  span:nth-child(1) {
    vertical-align: middle;
  }

  .ant-tag {
    margin-left: 4px;
    padding: 0 2px;
    font-size: 9px;
    line-height: 1.2;
    vertical-align: middle;
  }
}

.projectNo {
  color: #888;

  .anticon {
    cursor: pointer;
  }
}
