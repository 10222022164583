.content-nei {
  padding-left: 20px;
}

.title {
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
  border-left: #1a73e8 5px solid;
  font-weight: bold;
  margin-top: 20px;
}

.content {
  width: 100%;
  font-size: 18px;
  text-align: left;
  margin-left: 20%;
  margin-top: 20px;
  position: relative;
}
.iconVery{
  padding-left: 10px;
}
.buttonblue {
  width: 100px;
  background: #02a7f0;
  margin: 2px;
  margin-left: 50px;
  border: none;
  height: 40px;
  color: #ffffff;
}

.checkbutton {
  width: 120px;
  background: #f0f2f5;
  margin-top: 50px;
  margin-left: 120px;
  border: none;
  height: 40px;
  margin-bottom: 200px;
  color: #ffffff;
  background: #02a7f0;
  border-radius: 5px;
  cursor: pointer;
}

.checkbuttonb {
  width: 120px;
  background: #f0f2f5;
  margin-top: 50px;
  margin-left: 120px;
  border: none;
  height: 40px;
  margin-bottom: 200px;
  color: #02a7f0;
  background: #ffffff;
  border: #02a7f0 1px solid;
  border-radius: 5px;
  cursor: pointer;

}

.accessory {
  width: 120px;
  background: #f0f2f5;
  margin-left: 20px;
  border: none;
  height: 40px;
  color: #02a7f0;
  background: #ffffff;
  border: #02a7f0 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.input {
  background: #ffffff;
  margin: 2px;
  margin-left: 50px;
  border: solid 1px #bbbbbb;
  height: 40px;
  width: 30%;
}
.inputzz{
  background: #ffffff;
  margin: 2px;
  margin-left: 60px;
  border: solid 1px #bbbbbb;
  height: 40px;
  width: 30%;
}
.content {
  .ant-picker{
    width: 400px;
    height: 40px;
    font-size: 18px;
  }
  .space {
    background: #ffffff;
    margin: 2px;
    margin-left: 30px;
    margin-top: 20px;
    height: 40px;
    width: 30%;
    border: 0px;

    .ant-upload-list {
      margin-left: 50px;
    }

    .ant-btn-default {
      width: 120px;
      background: #f0f2f5;
      margin-top: 20px;
      margin-left: 5px;
      border: none;
      height: 40px;
      color: #02a7f0;
      background: #ffffff;
      border: #02a7f0 1px solid;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .ant-input{
    background: #ffffff;
    margin: 2px;
    margin-left: 60px;
    border: solid 1px #bbbbbb;
    height: 40px;
    width: 30%;
  }
}

.uploadButton {
  float: left;
  position: absolute;
  bottom: 0px;
  text-align: left;
  margin-left: 20px;
  .ant-upload-list {
  }
  .ant-btn-default {
    width: 120px;
    background: #f0f2f5;
    margin-top: 20px;
    border: none;
    height: 40px;
    color: #02a7f0;
    background: #ffffff;
    border: #02a7f0 1px solid;
    border-radius: 5px;
    cursor: pointer;
  }
}

.tags {
  background: #ffffff;
  margin: 2px;
  margin-left: 50px;
  font-size: 50px;
  height: 40px;
  width: 30%;
}

.inputname {
  background: #ffffff;
  margin: 2px;
  margin-left: 30px;
  border: solid 1px #bbbbbb;
  height: 40px;
  width: 30%;
}

.inputcontent {
  background: #ffffff;
  margin: 2px;
  margin-left: 50px;
  border: solid 1px #bbbbbb;
  height: 120px;
  width: 30%;
}

.image {
    display: inline-block;
    margin-left: 50px;
    margin-top: 20px;
    .ant-image-img{
      background: #f2f2f2;
      border: red 1px dashed;
  }
}

.icon:before {
  content: '*';
  margin-right: 5px;
  color: red;
}

.product-choice{
  margin-top: 10px;
}
.basic-people{
  margin-left: 40px;
}
.people-choice{
  font-size: 14px;
  margin-left: 160px;
  margin-top: 10px;
  .people-select{
    width: 200px !important;
  }
}

.class-authorization{
  margin-left: -18px !important;
}
