.main-schedule {

  .schedule-box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    > span {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }

  .schedule-content {
    margin-bottom: 30px;

    .schedule-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      > span {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }

    .schedule-table-head {
      width: 100%;
      display: flex;
      padding: 12px 0;
      background: #fafafa;
      border-bottom: 1px solid #f0f0f0;

      & > span {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        padding: 0 5px;
        box-sizing: border-box;
      }

      .schedule-head-start {
        width: 130px;
        //text-align: center;
      }

      .schedule-head-end {
        width: 130px;
        //text-align: center;
      }

      .schedule-head-name {
        width: 200px;
        //text-align: center;
      }

      .schedule-head-text {
        flex: 1;
        //text-align: center;
      }

      .schedule-head-settings {
        width: 140px;
        //text-align: center;
      }

    }
  }

  .timeline-node {
    z-index: 10000;
  }

  .schedule-table-tr {
    display: flex !important;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 0;

    &:hover {
      background: #fafafa;
    }

    .schedule-table-start {
      width: 130px;
      //text-align: center;
      padding: 0 5px;
      box-sizing: border-box;
    }

    .schedule-table-end {
      width: 130px;
      //text-align: center;
      padding: 0 5px;
      box-sizing: border-box;
    }

    .schedule-table-name {
      width: 200px;
      //text-align: center;
      padding: 0 5px;
      box-sizing: border-box;
    }

    .schedule-table-text {
      flex: 1;
      //text-align: center;
      padding: 0 5px;
      box-sizing: border-box;
      word-break: break-all;
    }

    .schedule-table-settings {
      width: 140px;
      //text-align: center;
      box-sizing: border-box;
    }

  }

}

.medproject-auth-type {
  .ant-checkbox-wrapper-in-form-item {
    margin: 0 5px 0 0 !important;
  }
}

.medproject-list {
  .editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  
  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
}
