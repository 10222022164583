.topics-drawer-footer{
    display: flex;
    justify-content: flex-end;
}
.topics-description{
    word-break: break-all;
}
.topicModalClassName .ant-form-item-label{
    pointer-events: none;
}

.topics-table{
    .ant-table-row-level-1{
        .indent-level-1{
            display: none;
        }
        .ant-table-row-expand-icon-spaced{
            display: none;
        }
        .ant-table-cell-with-append{
            padding-left: 30px;
        }
    }
}
.drawer-close-icon{
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 9999;
}
