.core-data-all{
    padding: 0 40px;
    text-align: center;
    p{
        line-height: 1;
    }
    .tendency-num{
        font-weight: 700;
    }
}
.data-hover-icon{
        margin-left: 5px;
        color: #000;
        font-size: 13px;
    }
.main-content-body{
    h2{
        font-weight: 600;
        font-size: 18px;
    }
}
.statistics-chart{
    position: relative;
    .echarts-p{
        position: absolute;
        left: 10px;
        z-index: 100;
    }
}
.echarts-module{
    height: 400px;
    width: 971px;
    margin-bottom: 20px;
}

.data-overview-chart{
    height: 300px;
    width: 500px;
}

.data-overview-img{
    width: 150px;
}
