.more-cloze-btn{
    // display: none !important;
    position: absolute !important;
    z-index: 9;
    top:6px;
    height: 30px!important;
    transition:0.2s all !important;
    &:focus{
        display: block !important;  
    }
}

.question-cloze:focus + .more-cloze-btn {
       display: block !important; 
    }

.ql-insertStar{
    width: 90px !important;
}

    .more-toolbar{
        display: none;
        position: absolute;
        z-index: 9;
        background-color: #fff;
        width: 230px;
       top: -10px;
    }

