.user-groups-h2{
    margin: 20px;
    margin-left: 0px;
}

.user-groups-box-header{
    margin-top: 10px;
    margin-bottom: 10px;
}

.basic-message{
    padding: 24px;
}

.user-group-rules-space{
    margin-left: 20px;
    margin-top: 30px;
    min-height: 300px;
}

.user-groups-box{
    // width: 900px;
    padding: 5px 15px;
    border: 1px solid #E0E6ED;
    .user-groups-box-header{
        overflow: hidden;
    }
    h3{
        font-weight: 600;
        font-size: 15px;
        float: left;
        margin-bottom: 0;
    }
}

.user-add-icon{
    margin-right: 5px;
    color: #1a73e8 !important;
}

.user-delete-icon{
    margin-left: 8px;
}

.user-add-box{
    color: #1a73e8;
    cursor: pointer;
}

.edit-condition-box{
    margin-left: 24px;
    padding-left: 5px;
    border-left: 2px solid #c2daf9;
    position: relative;
}
.edit-condition-box-noborder{
    padding-left: 5px;
    position: relative;
}
.edit-condition-box-queryType{
    position: absolute;
    text-align: center;
    line-height: 24px;
    background-color: #d4e5ff;
    left: -10px;
    top: 50%;
    margin-top: -12px;
    width: 20px;
    height: 24px;
    display: inline-block;
    color: #1a73e8;
    cursor: pointer;
}

.inner-content-box{
    padding-left: 15px;
    margin-bottom: 17px;
    position: relative;
}

.inner-add{
    position: absolute;
    right: 20px;
    top: 4px;
}

.user-group-tooltip{
    position: absolute;
    top: 5px;
    right: -15px;
    color: #aaaaaa;
}

.inner-content{
    margin-bottom: 12px;
    position: relative;
    .ant-select{
        width: 100%;
    }
}

.user-delete-icon{
    position: absolute;
    top: 8px;
    right: 9px;
}

.user-group-submit{
    margin-left: 24px;
    margin-top: 50px;
}

.user-groups-name{
    color: #aaa;
}

.disable-click{
    pointer-events: none;
}
