.content-nei{
 padding-left: 20px;
}
.title{
 width: 100%;
 font-size: 20px;
 padding-left: 10px;
 border-left: #1a73e8 5px solid;
 font-weight: bold;
 margin-top: 20px;
}
.content{
 width: 100%;
 font-size: 18px;
 text-align: left;
 margin-left: 20%;
 margin-top: 20px;
 .ant-tag{
  font-size: 14px;
  text-align: center;
  border: #02a7f0 1px solid;
  background: #ffffff;
  border-radius: 5px;
 }
}
.contentTag{
 width: 100%;
 font-size: 18px;
 text-align: left;
 margin-left: 20%;
 margin-top: 20px;
 display: flex;
 .ant-tag{
  font-size: 14px;
  text-align: center;
  border: #02a7f0 1px solid;
  background: #ffffff;
  border-radius: 5px;
 }
}
.divTags{
 margin: 2px;
 margin-left: 50px;
 border: none;
 width: 30%;
 font-size: 16px;
}

.bottom{
 width: 100%;
 height: 60px;
}
.button{
 width: 100px;
 background: #f0f2f5;
 margin: 2px;
 margin-left: 50px;
 border: none;
 height: 40px;
}
.checkbutton{
 width: 120px;
 background: #f0f2f5;
 margin-top: 50px;
 margin-left: 120px;
 border: none;
 height: 40px;
 margin-bottom: 200px;
 color: #ffffff;
 background: #02a7f0;
 border-radius: 5px;
 cursor: pointer;
}
.checkbuttonb{
 width: 120px;
 background: #f0f2f5;
 margin-top: 50px;
 margin-left: 120px;
 border: none;
 height: 40px;
 margin-bottom: 200px;
 color: #02a7f0;
 background:#ffffff;
 border: #02a7f0 1px solid;
 border-radius: 5px;
 cursor: pointer;

}
.input{
 background: #ffffff;
 margin: 2px;
 margin-left: 50px;
 border: solid 1px #bbbbbb;
 height: 40px;
 width: 30%;
}
.checkInput{
 background: #ffffff;
 margin: 2px;
 margin-left: 50px;
 border: none;
 height: 40px;
 width: 30%;
}
.checkInputcontent{
 padding-top: 10px;
 background: #ffffff;
 margin: 2px;
 margin-left: 130px;
 border: none;
 height: 120px;
 width: 30%;
 font-size: 16px;
 outline:none;resize:none;
}
.nameSpan{
 position: absolute;
 top: 10%;
}

.divTags{
 margin: 2px;
 margin-left: 50px;
 border: none;
 width: 30%;
 font-size: 16px;
}
.buttonblue{
 width: 100px;
 background: #02a7f0;
 margin: 2px;
 margin-left: 50px;
 border: none;
 height: 40px;
 color: #ffffff;
 cursor: pointer;
}
.checkInputname{
 background: #ffffff;
 margin: 2px;
 margin-left: 30px;
 border: none;
 height: 40px;
 width: 30%;
}
.inputcontent{
 background: #ffffff;
 margin: 2px;
 margin-left: 50px;
 border: solid 1px #bbbbbb;
 height: 120px;
 width: 30%;
}
.radioClass{
 margin-left: 50px;
}
.image{
 display: inline-block;
 margin-left: 50px;
}
.icon:before{
 content:'*';
 margin-right: 5px;
 color: red;
}
.select{
 margin-bottom: 20px;
}
