.cumputer-preview{
    height: calc(100vh - 208px);
    // min-width: 800px;
    // overflow-x: scroll !important;
    overflow-y:auto ;
    .iframe-width{
        min-width: 800px;
        height: calc(100vh - 220px);
        overflow-x: scroll !important;
        // transform: scale(0.9);
        // pointer-events: none;
        padding: 10px 0;
        border:none;
        overflow-y: auto;
    }
    .pointer-none{
        pointer-events: none;
    }
}
