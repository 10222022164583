.modules-page {
  display: flex;
  flex-direction: row;

  p {
    margin: 0;
  }

  .main-content-box {
    margin: 0;
  }

  .modules-side {

  }
  .modules-menu-box {
    width: 220px;
    height: fit-content;
    margin-right: 20px;
    border: 1px solid #EEE;
    border-width: 1px 1px 0;

    p {
      //height: 40px;
      //line-height: 40px;
    }

    .menu-title {
      background-color: #fafafa;
      text-align: center;
      padding: 8px 0;
    }


  }

  .menu-item-add {
    width: 220px;
    text-align: center;
    margin-top: 16px;
  }

  .modules-content {
    flex: 1;
  }
}

.module-menu-item {
  display: flex;
  justify-content: space-between;
  text-align: left;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  padding: 8px;

  &.active {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  .menu-item-title-box {
  }

  .menu-item-name {
    display: inline-block;
    vertical-align: center;
  }

  .menu-item-name-tip {
    color: #333333;
    font-size: 10px;
    padding: 0 2px;
    border-radius: 2px;
    vertical-align: center;

    &.hot {
      color: white;
      background-color: red;
      margin-left: 4px;
    }

    &.hidden {
      color: white;
      background-color: #fa8c16;
      margin-left: 4px;
    }
  }

  .menu-item-option {
    display: flex;
    align-items: center;
  }


  .anticon {
    margin-left: 8px;
  }

  &:hover {
    background-color: #e6f7ff;
    color: #1890ff;
  }
}

.module-modal {

  .module-tips {
    color: #999999;
    margin-bottom: 20px;
  }

  .ql-container {
    max-height: 300px;
    overflow-y: scroll;
  }

}

