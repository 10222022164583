.notice-body{
    .main-tools{
        margin-bottom: 20px;
    }

    .notice-list{

        .ant-list-item-extra{
            flex-shrink: 0;
            margin-left: 0;
        }

        .ant-list-item-action{
            margin-top: 0!important;
        }

        .ant-list-item-meta {
            margin-bottom: 0!important;
        }
        .notice-content{

            &>span{
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 62.5rem;
            }

        }

        .operationArea{
            display: flex;
            height: 100%;

            .operateButton{
                align-self: flex-end;
                display: flex;
                margin-left: 5px;
            }
        }
    }
}
