.production-uploader{
    width: 120px !important;
    height: 40px !important;
    >div{
        width: 120px !important;
        height: 40px !important;
    }
}

.production-img{
    width: 120px;
    height: 40px;
}

.enterprise-img{
    width: 180px;
    height: 46px;
}
