.table-toolbar-role{
    // float: right;
    overflow: hidden;
}
.table-tag-filter{
    margin-bottom: 20px;
}
.add-role-button{
    float: left;
}
.search-role-box{
    float: right;
}

.checkbox-role{
    margin-top: 5px;
}

.roles-tabs{
    .ant-tabs-tab{
        padding: 12px 24px !important;
    }
}

.roles-tabs-title{
    // font-weight: 600;
    margin-right: 10px;
}

.roles-tabs-content{
    margin-left: 24px;
    .ant-checkbox-group{
        width: 100%;
        .ant-row{
            margin-left: 24px;
            .ant-col{
                margin-bottom: 15px;
            }
        }
    }
}

.roles-checkbox{
    width: 100%;
    .ant-col{
        margin-bottom: 10px;
    }
}

.role-permission-title-box{
    .role-permission-title-button{
        float: right;
    }
}

.role-message-card{
    margin-bottom: 20px !important;
}

.main-content-box-role{
    margin: 24px 24px 0;
    border: 1px solid #eee;
}

.member-information{
    margin-left: 40px;
}

.title-checkbox-length{
    margin-left: 5px;
    background-color: red;
    color: #fff;
    text-align: center;
    width: 21px;
    border-radius: 50%;
    height:21px;
    line-height: 21px;
    font-size: 12px;
    transform: scale(0.8);
    display: inline-block;
}

.roles-checkbox-info{
    margin-left: 20px;
    color: #999;
}
.role-permission-checkbox-form{
    .ant-form-item{
        margin-bottom: 0;
    }
}

.span-display-none{
    display: none;
}

.role-permission-bottom-button{
    float: right;
    margin-right: 15px;
    margin-top: 30px
}
