.guest-journey-content {
    .system-title {
        position: relative;
        left: -9px;
        color: #7D7979;

        span {
            color: #000000D9;
        }
    }

    .hotel-title {
        display: inline-block;
        min-width: 75px;
        color: #7D7979;
    }

    .info-title {
        display: inline-block;
        min-width: 45px;
        color: #7D7979;
    }

}

.journey-total-info {
    margin-bottom: 10px;
}

.guest-journey-btns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
