.content-nei{
    padding-left: 20px;
}
.span-black{
    color: black;
}
.span-red{
    color: red;
}
.postpone{
    font-size: 16px;
}
.postponeDiv{
    margin-top: 10px;
}
.ant-input{
    margin-left: 20px;
}
.icon:before{
    content:'*';
    margin-right: 5px;
    color: red;
}

