.logic_size{
    height: calc(100vh - 300px);
}
.react-flow{
        height: 80vh !important;
}
.react-flow__attribution{
    display: none;
}
.control_tools{
    height: 30px;
}

.logic_box{
    display: flex;
    padding: 12px;
    margin: 12px;
    background-color: #fff;
    width: 100%;
    height: 85vh;
    .logic_left{
        width: 45vw;
        height: 100%;
        padding-right: 10px;
        overflow-y: auto;
        border-right: 1px solid #ddd;
        .logic_set{
            position: sticky;
            top: 0;
            z-index: 99;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            .logic_set_left{
                display: flex;
                flex: 1;
                span{
                    margin-top: 8px;
                    color: #aaa;
                }
            }
        }
    }
    .logic_right{
        width: 45vw;
        margin-left: 12px;
        position: relative;
    }
    h2{
        border-left:5px solid #1a73e8;
        padding-left: 4px;
    }
    .logic_item{
        width: 100%;
        height: 120px;
        background-color: rgba(24, 144, 255, 0.0431372549019608);
        position: relative;
        overflow: hidden;
        padding-top: 8px;
        .logic_item_foot{
            // display: flex;
           
            // justify-content: space-between;
        }
        .logic_item_del{
            position: absolute;
            right: 10px;
            bottom: 20px;
        }
        .logic_item_sucess{
            position: absolute;
            right: 30px;
            bottom: 20px;
        }
    }
}

#toolbar{
    position: absolute;
    width: 200px;
    top: 42px;
    li:nth-child(1),li:nth-child(2){
        display: none;
    }
}
#minimap{
    position: absolute;
    width: 200px;
    bottom: 0;
    right: -17%;
}
#container{
    height:95%;
    width: 100%;
    canvas{
        width: 100%;
        height: 100% !important;
    }
}
