.main-content-box.cases-table-content {
    padding: 20px;

    .cases-table-header {
        display: flex;
        align-items: center;

        input {
            margin-right: 16px;
        }

        span {
            flex-shrink: 0;
        }

        .assignment-buttons {
            margin-left: 16px;
        }
    }

    .lasttime-span {
        color: #1089da;
        cursor: pointer;
    }
}

.cases-table-casesHeader {
    display: flex;
    align-items: center;
    gap: 12px;
}
.cases-table-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 300px;
}

.modal-fraction-score {
    margin: 0 0 12px 82px;
}

.modal-flex-div {
    display: flex;
    align-items: center;
    margin-left: 40px;

    span {
        flex-shrink: 0;
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    justify-content: flex-end;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.ant-pagination.my-pagintation-class {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
