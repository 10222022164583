.facade-action{
    height: calc(100vh - 140px);
    padding: 10px;
}
.cumputer-preview{
    height:calc(100vh - 80px); 
    overflow-y: auto;
}
.mobile-preview{
    height:calc(100vh - 80px);
    overflow-y: auto;
}
