.answer-text {
  width: 100%;
  color: #1890ff;
  display: flex;

  .answer-label {
    width: 42px;
  }

  .answer-content {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
