.interaction{
    margin: 24px 24px 0;
    padding-bottom: 20px;
    p{
        margin-bottom: 10px !important;
        line-height: 1;
    }
}

.message-col{
    max-width: 150 !important;
}


.interaction-part{
    background-color: #fff;
    padding: 20px 20px;
    position: relative;
    p{
        color: #999;
    }
    .chat-icon{
        font-size: 25px;
        margin-right: 10px;
    }
    .look-link{
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 15px;
        a{
            color: #999;
        }
        color: #999;
    }
}

.interaction-part .interaction-num{
    font-weight: 700;
    font-size: 16px;
    color: #000;
}
