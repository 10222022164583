
.uploadIconBox{
    display: flex;
    align-items: flex-end;

    .uploadWap{
        width: 120px;
    }

    .uploadImageTips{
        padding-bottom: 8px;
        color: #999999;
    }

    .imageWarp{
        height: 100%;
        overflow: hidden !important;
    }
}

.iconLists{

    padding: 10px 0;
    display: flex;

    &>div{
        width: 60px;
        height: 60px;
        margin-right: 20px;
        padding: 4px;
        border: 1px #eeeeee solid;
        cursor: pointer;
        border-radius: 4px;

        &.iconAct{
            border: 1px #1f31ff solid;
        }

        &>img{
            width: 100%;
        }
    }
    
}
