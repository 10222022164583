.med-project-module-head {
  display: flex;

  .module-title {
    flex: 1;
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin-bottom: 0;
      flex-shrink: 0;
    }

    .tips {
      margin-left: 1em;
      color: #888;

      em {
        color: #fa8c16;
        font-style: normal;
      }
    }
  }

}

.modules-content{
  .ant-space,.ant-space-item{
      width: 100%;
  }
}

.med-project-module-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .main-tools {
    display: flex;

    .ant-form-inline .ant-form-item-with-help {
      margin-bottom: 0;
    }
  }

}


