.signin-detail {
    p {
        margin: 0;
    }

    .detail-header {
        .ant-form {
            width: 100%;
        }
    }

    .signup-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .signup-left {
            .ant-btn {
                margin-right: 20px;
            }
        }
        .signup-right {
            .ant-btn {
                margin-left: 20px;
            }
        }
    }

    .edit-btn {
        padding-left: 0;
    }

}

.signInModal {
    .ant-input-number {
        width: 100% !important;
    }
}

.importDataModal .ant-modal-body {
    height: 300px;

    .fileInfo {
        margin-left: 70px;
        margin-top: 10px;

        p:last-of-type {
            color: #666666;
        }
    }
}

.confirmImportDataModal {
    width: calc(100vw - 200px) !important;

    .ant-modal-content {
        width: 100%;
        height: calc(100vh - 200px);

        .ant-modal-body {
            overflow-y: auto;

            .fileInfo {
                margin-left: 70px;
                margin-top: 10px;

                p:last-of-type {
                    color: #666666;
                }
            }
        }
    }
}
