.to-time{
    margin-left:-14px ;
    margin-right: 2px;
    margin-top: 8px;
}
.to-time-last{
    margin-left:-14px ;
    margin-right: 18px;
    margin-top: 8px;
}
.error-time{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 20px;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #A30014;
    line-height: 18px;
}
.interval{
    width: 100%;
     margin-top:10px ;
     height: 30px;
    background-color: #f0f2f5;
}
.time-box{
    width: 350px;
    display: flex;
    flex-wrap: wrap;
    .time-area{
        width: 100px !important;
    }
}


.detail-btn-area{
    display: flex;
    padding:8px 24px;
    button{
        margin-right: 16px;
    }
    // justify-content: flex-start;
}
.export-btn{
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 0;
}
.details-table{
    padding: 4px 24px;
}
.channel-slect{
    .ant-select{
        width: 300px !important;
    }
}
.data-tabs{
    .ant-form-item {
        padding: 5px;
    }
}
