.signin-wrap {
    p {
        margin: 0;
    }

    .signin-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .signin-tip {
            padding-left: 20px;
            color: #00000072;
        }
    }

    .edit-btn {
        padding-left: 0;
    }
}
