.ant-tabs-nav-list{
    margin-left: 20px;
}
.content-nei{
    padding-left: 20px;
}
.title{
    width: 100%;
    font-size: 20px;
    padding-left: 10px;
    border-left: #1a73e8 5px solid;
    font-weight: bold;
    margin-top: 20px;
}
.content{
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-left: 20%;
    margin-top: 20px;
}
.formalContent{
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-left: 20%;
    margin-top: 20px;
    .ant-input{
        background: #ffffff;
        margin: 2px;
        margin-left: 50px;
        border: solid 1px #bbbbbb;
        height: 40px;
        width: 18%;
    }
}
.bottom{
    width: 100%;
    height: 60px;
}
.button{
    width: 100px;
    background: #f0f2f5;
    margin: 2px;
    margin-left: 50px;
    border: none;
    height: 40px;
}
.buttonblue{
    width: 100px;
    background: #02a7f0;
    margin: 2px;
    margin-left: 50px;
    border: none;
    height: 40px;
    color: #ffffff;
    cursor: pointer;
}
.checkbutton{
    width: 120px;
    background: #f0f2f5;
    margin-top: 50px;
    margin-left: 120px;
    border: none;
    height: 40px;
    margin-bottom: 200px;
    color: #ffffff;
    background: #02a7f0;
    border-radius: 5px;
    cursor: pointer;
}
.nametext{
    background: #ffffff;
    margin: 2px;
    margin-left: 50px;
    height: 40px;
    width: 30%;
    font-size: 18px;
}
.contentTag{
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-left: 20%;
    margin-top: 20px;
    display: flex;
    .ant-tag{
        font-size: 14px;
        text-align: center;
        border: #02a7f0 1px solid;
        background: #ffffff;
        border-radius: 5px;
    }
}
.divTags{
    margin: 2px;
    margin-left: 50px;
    border: none;
    width: 30%;
    font-size: 16px;
}
.devContentTag{
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-left: 20%;
    margin-top: 10px;
    display: flex;
    .ant-tag{
        font-size: 14px;
        text-align: center;
        border: none;
        background: #ffffff;
        border-radius: 5px;
        color: #bbbbbb;
        cursor:pointer;
    }
}
.divContTags{
    margin: 5px;
    margin-left: 130px;
    border: none;
    width: 40%;
    font-size: 16px;
}
.divContInputTags{
    margin: 5px;
    margin-left: 130px;
    width: 40%;
    font-size: 14px;
}
.inputname{
    background: #ffffff;
    margin: 2px;
    margin-left: 30px;
    border: solid 1px #bbbbbb;
    height: 40px;
    width: 30%;
}
.inputcontent{
    background: #ffffff;
    margin: 2px;
    margin-left: 50px;
    border: solid 1px #bbbbbb;
    height: 120px;
    width: 30%;
}
.radioClass{
    margin-left: 50px;
}
.image{
    display: inline-block;
    margin-left: 50px;
}
.icon:before{
    content:'*';
    margin-right: 5px;
    color: red;
}
.classBottom{
    width: 100%;
    height: 100px;
}
.createdBy{
    border-bottom: #02a7f0 1px solid;
    width: 50%;
    margin-top: 5px;
    font-size: 14px;
}
.spanRight{
    margin-left: 50px;
    font-size: 14px;
}
.divContent{
    margin-top: 5px;
    font-size: 14px;
}
.logTimeSpan{
    color: #389e0d;
    font-size: 16px;
}
.contractDatatext{
    background: #ffffff;
    margin: 2px;
    margin-left: 30px;
    height: 40px;
    width: 30%;
    font-size: 20px;
}
.sponsortext{
    background: #ffffff;
    margin: 2px;
    margin-left: 70px;
    height: 40px;
    width: 30%;
    font-size: 18px;
}
.buttontext{
    background: #ffffff;
    margin: 2px;
    margin-left: 10px;
    height: 40px;
    width: 30%;
    font-size: 18px;
}
.buttonyulan{
    width: 120px;
    background: #f0f2f5;
    margin-left: 10px;
    border: none;
    height: 40px;
    color: #02a7f0;
    background:#ffffff;
    border: #02a7f0 1px solid;
    border-radius: 5px;
    cursor: pointer;
}
.updateButtonyulan{
    width: 140px;
    background: #f0f2f5;
    margin-left: 40%;
    border: none;
    height: 40px;
    color: #02a7f0;
    background:#ffffff;
    border: #02a7f0 1px solid;
    border-radius: 5px;
    cursor: pointer;
}
.ant-tree-treenode{
    font-size: 14px;
}
.classTree{
    padding-right: 20px;
    margin-top: 50px;
    width: 20%;
    position: absolute;
    height: 800px;
}
.contentTab{
    font-size: 18px;
    text-align: left;
    margin-left: 25%;
    margin-bottom: 50px;
    width: 70%;
}
.formalCheckInputUpdate{
    background: #ffffff;
    margin: 2px;
    margin-left: 50px;
    height: 40px;
    width: 30%;
    border: #bbbbbb 1px solid;
}
.formalIpInput{
    margin: 2px;
    margin-left: 50px;
    height: 40px;
    width: 18%;
}
.explain{
    font-size: 14px;
    margin-left: 20px;
    color: #bbbbbb;
    margin-top: 10px;
}
.imgExplain{
    font-size: 14px;
    margin-left: 20%;
    padding-left: 30px;
    margin-top: 10px;
    color: #bbbbbb;
    margin-top: 20px;
}
.formalInputcontentUpdate{
    padding-top: 10px;
    background: #ffffff;
    margin: 2px;
    margin-left: 130px;
    height: 120px;
    width: 30%;
    font-size: 16px;
    border: #bbbbbb 1px solid;
}
.formalInputInfoUpdate{
    background: #ffffff;
    margin: 2px;
    margin-left: 40px;
    height: 40px;
    width: 30%;
    border: #bbbbbb 1px solid;
}
.formalInputnameUpdate{
    background: #ffffff;
    margin: 2px;
    margin-left: 30px;
    height: 40px;
    width: 30%;
    border: none;
}

