@import '~antd/es/style/themes/default.less';

.caseForm {
    max-width: 900px;
    padding-top: 20px !important;
    margin: 0 auto !important;

    .projectTitle {
        font-size: 16px;
        color: #444;
        font-weight: 500;
    }

    .ant-input-prefix {
        color: #999;
    }

    .topicDescription {
        background-color: rgba(133, 144, 166, 0.05);
        border-radius: 6px;
        padding: 10px 16px;
        color: #444;
        word-break: break-all;
    }

    .coverImage {
        width: 320px;
        height: 180px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        margin-bottom: 10px;
        border: 1px solid #EEE;
    }

    .newItem {
        display: flex;
        align-items: center;
        color: @blue-6;
        cursor: pointer;

        &:hover {
            color: @blue-5;
        }

        svg {
            margin-right: 4px;
        }
    }

    .btnWithIcon {
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }
    }

    .materials {
        display: flex;
        justify-content: space-between;
        margin: 5px 0 5px;
    
        a {
          margin-left: 10px;
          .anticon {
            margin-right: 3px;
          }
        }
        .fileOperation {
          display: inline-block;
          min-width: 150px;
          text-align: right;
        }
      }

    .imgListBox {
        display: flex;
        flex-wrap: wrap;
    }

    .imgWithDesc {
        display: flex;
        align-items: center;
        width: 100%;
        background: rgba(133, 144, 166, 0.05);
        border-radius: 6px;
        padding: 8px;
        margin-bottom: 10px;


        .imgBox {
            width: 160px;
            height: 90px;
            margin-right: 10px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .imgDesc {
            flex: 1;
        }


    }

    .fieldOperationsWithPreview {
        display: flex;
        align-items: center;
        width: 220px;
        margin-left: 10px;

        .fieldOperation {
            display: flex;
            align-items: center;
            color: #8c8c8c;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &:hover {
                color: #444;
            }

            svg {
                margin-right: 4px;
            }
        }
    }

    .formOperations {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }

    .fieldListItem {
        display: flex;
        align-items: center;
        margin: 8px 0;

        .ant-select {
            flex: 1;
        }

        .ant-input {
            flex: 1;
        }
    }

    .fieldOperations {
        display: flex;
        //justify-content: space-around;
        align-items: center;
        width: 220px;
        margin-left: 10px;

        .fieldOperation {
            display: flex;
            align-items: center;
            color: #8c8c8c;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 6px;
            }

            &:hover {
                color: #444;
            }

            svg {
                margin-right: 4px;
            }

        }
    }

    .fieldOperationsAdd {
        margin-left: 0;
    }

    .docBox {
        display: flex;
        align-items: center;
        background: rgba(133, 144, 166, 0.05);
        border-radius: 6px;
        padding: 8px;
        margin-bottom: 10px;

        .docIcon {
            display: flex;
            align-items: center;
            margin-right: 4px;
            color: #888;
        }

        .docName {
            flex: 1;
            color: #444;
        }

        .docOperations {

            .docOperation {
                display: flex;
                align-items: center;
                color: #8c8c8c;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 8px;
                }

                &:hover {
                    color: #444;
                }

                svg {
                    margin-right: 4px;
                }
            }
        }

    }

    .ant-form-item-label {
        min-width: 160px !important;
    }

    .ant-col-sm-offset-4 {
        margin-left: 160px;
    }

    .ant-input-number {
        width: 100% !important;
    }

    .fieldInputCascader {
        width: calc(100% - 200px) !important;
        min-width: calc(100% - 200px) !important;
    }

}

.copyrightModal {
    .copyright {
        font-size: 16px;
        text-indent: 2em;
    }

    .ant-modal-footer {
        text-align: center;
    }
}

.ant-cascader-dropdown .ant-cascader-menus {
    .ant-cascader-menu {
        &:nth-child(1) {
            max-width: 200px;
        }

        &:nth-child(2) {
            max-width: 200px;
        }

        &:nth-child(3) {
            max-width: 300px;
        }

        .ant-cascader-menu-item-content {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
