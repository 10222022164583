.signin-statistics {
    p {
        margin: 0;
    }

    .statistics-wrap {
        margin: 20px 0 50px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        overflow-x: hidden;

        .statistics-item {
            height: 80px;
            text-align: center;
            height: 100%;
            border: 1px solid #f0f0f0;
            margin: 0 10px 10px 0;
            width: calc(25% - 10px);
            min-width: calc(25% - 10px);
            max-width: calc(25% - 10px);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border-right: 1px solid #f0f0f0;
            padding: 20px 0;

            p:first-of-type {
                color: #0000006D;

                .anticon {
                    margin-left: 3px;
                }
            }

            p:last-of-type {
                color: #000000D8;
                font-size: 16px;
                font-weight: 600;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}
