.cumputer-box{
    height: calc(100vh - 208px);
    overflow-y:auto ;
    .iframe-width{
        width: 100%;
        height: calc(100vh - 220px);
        // transform: scale(0.9);
        // pointer-events: none;
        padding: 10px 0;
        border:none;
        overflow-y: auto;
    }
}
