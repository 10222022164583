.edit_row{
    display: flex;
    .row{
        text-align: center;
    }
    .row:hover{
        border: 1px dashed  #1a73e8 ;
    }
    .edit_action{
        display: flex;
        align-items: center;
    }
}
