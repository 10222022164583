.medical-mark-box{
    background-color: #fff;
    margin: 24px;
    padding: 24px;
    .medical-mark-date-title{
        display: flex;
        width: 100%;
        justify-content: space-between;
        .medical-mark-date-left{
            overflow: hidden;
            font-size: 18px;
        }
        .medical-mark-date-right{
            display: flex;
        }
    }
    .btn-container {
        margin-right: 10px;
        button {
            color: #333;
    
            &.active {
                color: #1890ff;
            }
        }
    }
    .ant-divider-horizontal{
        margin: 15px 0 24px 0 !important;
    }
    .medical-mark-date-content{
        display: flex;
        .medical-mark-date-content-box{
            width: 232px;
            height: 102px;
            background-color: rgba(250, 250, 250, 1);
            padding: 16px;
        }
        :nth-child(1){
            margin-right: 20px;
        }
    }
    .total-count{
        font-size: 20px;
    }
}
.position-chart,.data-overview{
    width: 100%;
    height: 350px;
}
