.conference-halls-area {
  background-color: white;
  //padding: 16px;
  height: 100%;

  .conference-halls-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin-bottom: 0;
      font-size: 18px;
    }

    .btns {
      margin-left: 1rem;
    }
  }

  .conference-halls {
    min-height: 400px;

    .ant-tabs-nav-list {
      margin-left: 0;
    }
  }
}

.halls-hall {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  margin-bottom: .5rem;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &:hover {
    background-color: #FAFAFA;
  }

  // 会议选中后的状态
  &.hall-selected {
    background-color: #e6f7ff;
    border-color: #91d5ff;

    &:hover {
      background-color: #bae7ff;
    }
  }

  &.sub-hall {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    margin-bottom: .5rem;
    margin-left: 24px;
  }

  .hall-info {
    flex: 1;

  }

  .hall-title-box {
    display: flex;
  }

  .hall-title {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1;
    min-width: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;

    > span {
      display: inline-block;
      width: 100%;
      //white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;
    }

    .id {
      color: #888;
      margin-left: 8px;
      font-weight: normal;
    }
  }

  .hall-extra {
    padding-top: 8px;
    color: #444;
  }

  .hall-extra-info {
    label {
      color: #888;
    }

    .time {
      margin-right: 16px;
    }
  }
}

.agenda-area {
  background-color: white;
  //padding: 16px;
  height: 100%;

  .agendas-title {
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-bottom: 0;
      font-size: 18px;
    }

    .agenda-operations {
    }
  }

  .current-hall {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;

    small {
      color: #888;
      font-size: 14px;
      font-weight: normal;
    }
  }

  .conf-extra {
    //padding: 10px;
    //background-color: #FAFAFA;
    //border: 1px solid #F0F0F0;

    > div:not(:last-child) {
      margin-bottom: 8px;
    }

    .directors {

    }

    .implementers {
      margin-left: 24px;
    }

    .sponsor-info {
      margin-left: 24px;
    }
  }

  .agenda-summary {
    //margin-bottom: 16px;
  }

  .agendas {
    //background-color: #F2F2F2;
    //border-radius: 4px;
  }

}

.conference-halls-area,
.agenda-area {
  .ant-tag {
    margin-left: 4px;
    margin-right: 10px;
    font-size: 12px;
  }
}

.agendas-agenda {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  margin-bottom: .5rem;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &:hover {
    background-color: #FAFAFA;
  }

  .agenda-info {
    flex: 1;
  }

  .agenda-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    .ant-tag {
      font-weight: normal;
    }
  }

  ul.agenda-tasks {
    color: #666;
    margin-top: 8px;
    margin-bottom: 0;
    padding-inline-start: 24px;

    li {
      list-style: disc;
    }
  }

  .agenda-foot {
    display: flex;
    padding-top: 8px;
  }

  .agenda-datetime {
    color: #888;
    //margin-right: 24px;
    width: 120px;
  }

  .agenda-guests {
    flex: 1;
    color: #888;

    .guest {
      margin-right: 6px;
      vertical-align: middle;
    }

    .ant-tag {
      line-height: 1.2;
      padding: 0 4px;
      vertical-align: middle;
    }
  }
}

// 覆盖全局样式导致 - 表单校验样式错乱
.conference-form,
.agenda-form {
  //.ant-form-item-with-help {
  //    margin-bottom: 24px;
  //}

  .guest-item {
    //border: 1px solid #d9d9d9;
    margin-bottom: 10px;
    padding: 6px 8px;
    background-color: #F4F4F4;
    border-radius: 4px;
  }

  .ant-space:first-child {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .ant-space:last-child {
    .ant-form-item {
      margin-bottom: 0;
    }

    .anticon {
      cursor: pointer;
    }
  }
}

// 会议地点下拉框样式

.place-content {
  display: flex;
  justify-content: space-between;

  .place-title-name {
    flex: 1;
    white-space: break-spaces;
    padding-right: 8px;
  }

  .place-right-option {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .right-icon-edit,
    .right-icon-delete {
      flex: 1;
    }
  }
}

// 修改select下拉框样式
.first-conference {
  .ant-select-item-option {
    display: block !important;
  }
}

.page-title {
  .current-title_conference {
    min-width: 80px;
  }

  .current-subtitle_conference {
    font-size: 16px;
    margin-left: 1rem;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.conference-header-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.printAgendaModal {
  .ant-modal-body {
    text-align: center;
    min-height: 150px;
    line-height: 150px;

    .downloadAgendaModal {
      min-height: 150px;
      text-align: left;
      line-height: normal;

      p {
        margin: 0;
      }

      .ant-alert {
        margin-bottom: 20px;
      }

      .ant-btn {
        padding: 0;
      }
    }
  }
}

.place-overview-modal {
  .ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    min-height: 200px;
  }
}

.place-overview-container {
  width: 100%;
  max-height: 600px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: #E0E0E0;
    border-radius: 6px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #CCC;
    border-radius: 6px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #BBB;
  }

  .empty-overview {
    line-height: 10em;
    text-align: center;
    color: #888;
  }

}

#places-overview-table-box {
  width: fit-content;
  padding: 16px;

  table {
    th, td {
      border-collapse: collapse;
      border: 1px solid #CCC;
      padding: 2px 4px;
      font-size: 16px;
      line-height: 1.5em;
    }

    th {
      text-align: center;
      background-color: #E8E8E8;

      &.place {
        width: 160px;
      }

      &.hall {
        width: 320px;
      }
    }

    td {
      min-height: 40px;

      .empty {
        font-size: 14px;
        color: #888;
      }
    }

    .place {
      font-weight: 600;
    }

    .place-hall {
      .hall-box {
        &:not(:last-child) {
          margin-bottom: 1em;
        }

        .hall-time {
          font-size: 14px;
          color: #888;
        }

        .hall-name {
          small {
            font-size: 14px;
            color: #888;
          }
        }
      }
    }

  }
}
