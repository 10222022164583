.ProjectRangPicker {
    width: 100%;
    input {
        text-align: center;
    }
}

.ant-table.ant-table-scroll-horizontal .ant-table-title {
    padding: 0;
    
    .projects_table_title {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
    }
}

