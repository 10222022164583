.permission-icon{
    color: #1a73e8 !important;
    font-size: 15px;
}

.permission-left-top{
    margin-bottom: 12px;
    .ant-input-suffix{
        color: #ccc;
    }
}

.site-tree-search-value {
    color: #f50;
}

.tree-sort-title{
    margin-bottom: 15px;
    span{
        color: red;
    }
}
