.filter-form-buttons {
  text-align: right;
  margin-bottom: 24px;
}
.icon-vip{
  width: 20px;
  position: relative;
  top: -2px;
  margin-right: 2px;
}
.total-style{
  color: black;
}

