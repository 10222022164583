.labor-expert-modal {
    width: calc(100vw - 100px) !important;
    max-width: 1600px !important;

    .ant-modal-content {
        min-height: 600px;
        max-height: calc(100vh - 100px) !important;
        overflow-y: auto;
    }

    .labor-form-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .ant-form {
            display: flex;
            align-items: center;

            .ant-form-item {
                margin-bottom: 0;
                margin-right: 10px;
            }
        }

        .expert-count {
            font-weight: bold;
        }
    }
}
