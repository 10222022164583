.images-topic {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;

    .images-item {
      display: flex;
      align-items: center;
      // justify-content: center;
      position: relative;
      // width: 104px;
      // height: 104px !important;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      margin: 0 10px 10px 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      .ant-image {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .file-item {
      word-break: break-all;
    }
}
