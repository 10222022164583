.guest-journey-modal {

  .ant-drawer-body {
    //overflow: auto;
    //
    //&::-webkit-scrollbar {
    //  width: 10px;
    //  height: 10px;
    //}
    ///* Track */
    //&::-webkit-scrollbar-track {
    //  background: #E0E0E0;
    //  border-radius: 10px;
    //}
    ///* Handle */
    //&::-webkit-scrollbar-thumb {
    //  background: #BBB;
    //  border-radius: 10px;
    //}
    ///* Handle on hover */
    //&::-webkit-scrollbar-thumb:hover {
    //  background: #BBB;
    //}
  }

  .guest-journey-from {
    width: 1280px;
    margin: 0 auto;
  }

  .guest-journey-subtitle {
    font-size: 16px;
    font-weight: 600;
    color: #888;
    line-height: 3em;
  }

  .guest-info {
    border: 1px solid #ffe58f;
    background-color: #fffbe6;
    padding: 8px 16px;
    border-radius: 4px;
    margin-bottom: 20px;
    line-height: 2em;

    .guest-name {
      margin-right: 20px;
      font-weight: 600;
    }
    .expert-level {
      margin-right: 20px;
    }

    ol.unit-post-list {
      list-style: decimal;
      padding-left: 28px;
      margin-bottom: 0;
      li {
        list-style: decimal;
      }
    }

  }

  .guest-identity-info {

  }

  .guest-task-table-box {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 20px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #E0E0E0;
      border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #BBB;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #BBB;
    }
  }

  table.guest-task-table {
    border-collapse: collapse;


    th, td {
      padding: 4px 10px;
      border: 1px solid #DDD;
      width: 350px;
    }

    th {
      text-align: center;
      background-color: #F0F0F0;
    }

    .agendas {
      .agenda-box {
        &:not(:last-child) {
          margin-bottom: .5em;
        }
      }
      .agenda {}
      .agenda-datetime {
        color: #888;
        font-size: 14px;
      }
    }
  }

  .contacts-box {

    .contact-list {
      padding: 0 16px;
      background-color: #FAFAFA;
      border-radius: 4px;

      .contact {
        padding-top: 16px;
      }
    }

  }

  .ai-link-text {
    position: relative;
    display: inline-block;
    background-image: linear-gradient(to right, #b32dd8, #0064c8, #b32dd8);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;

    font-size: 12px;
    margin-left: 16px;
    border: 1px solid #DDD;
    line-height: 20px;
    padding: 2px 10px;
    border-radius: 28px;
    cursor: pointer;
  }

  .guest-journey-details {
    table {
      border-collapse: collapse;


      th, td {
        padding: 4px 10px;
        border: 1px solid #DDD;

        p {
          margin-bottom: .5em;
        }
      }

      th {
        text-align: center;
        background-color: #F0F0F0;
      }

      tr.system-assertion {
        td {
          background-color: #fffbe6;
        }
      }


    }

    .ant-divider-horizontal {


      &.ant-divider-with-text {
        margin: 10px 0;
        font-size: 14px;
      }
    }

    //.ant-picker-input > input {
    //  font-size: 10px;
    //}

  }

  .visible-to-user,
  .invisible-to-user {
    font-size: 12px;
    color: #fa8c16;
  }

  span + .visible-to-user,
  span + .invisible-to-user {
    margin-left: 10px;
  }

  .ant-picker-input > input {
    font-size: 12px;
  }


}

.guest-journey-ai-input-modal {

  .ant-modal-title {
    display: inline-block;
    background-image: linear-gradient(to right, #b32dd8, #0064c8, #b32dd8);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
  }

  .ai-input-text {}
  .ai-input-btn {}
  .ai-output-title {
    //display: inline-block;
    //background-image: linear-gradient(to right, #b32dd8, #0064c8, #b32dd8);
    //color: transparent;
    //background-clip: text;
    //-webkit-background-clip: text;
    //-moz-background-clip: text;

    margin-top: 10px;
  }
  .ai-output-text {
    min-height: 200px;
    max-height: 300px;
    background-color: #F8F8F8;
    border-radius: 4px;
    padding: 10px;
    overflow-y: auto;

    .guest-info-title {
      font-weight: 600;
      margin-bottom: .5em;
      padding-bottom: .25em;
      color: #888;
      border-bottom: 1px solid #DDD;
    }

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #E0E0E0;
      border-radius: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #BBB;
      border-radius: 10px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #BBB;
    }

    p {
      margin-bottom: .5em;
    }

  }
}
