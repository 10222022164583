.answer-text {
    p+p {
        padding-bottom: 16px;
        border-bottom: 1px solid #eee;
    }
}

.page-title-tip {
    font-size: 14px;
    margin-left: 20px;
    color: #db4437;
}

// 添加问卷样式
.ant-drawer-close {
    position: absolute;
    right: 10px;
}

.filter-form-buttons {
    text-align: right;
    margin-bottom: 24px;
}

.surveys-add-filter {
    .ant-form-item{
        margin-bottom: 10px;
    }
}

.surveys-add-center{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.surveys-add{
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
        padding: 16px 5px;
    }
}

.ant-pagination-total-text{
    float: right;
}
