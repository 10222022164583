.chart_view_wrap{
    .chart_view{
        margin: 0 auto;
        width: 600px;
        height: 700px !important;
    }
    .tooltip_text_wrap{
        max-width: 500px !important;
        >div{
            >div{
                &:first-of-type{
                    >div{
                        &:first-of-type{
                            white-space: normal !important;
                            word-break: break-all;
                        }
                    }
                    >span{
                        white-space: normal !important;
                        word-break: break-all;
                    }
                }
            }
        }
    }
}
