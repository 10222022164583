.solutions-page {
    .solutions-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            margin: 0 10px 0 0;
        }
    }

    .solutions-content {
        .solutions-search {
            margin-bottom: 20px;

            .ant-form  {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .ant-form-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.add-solutions-modal {
    .relate-solutions-form { 
        .ant-form-item-row {
            display: block !important;
        }
    }

    .ant-form-item {
        margin-bottom: 10px;
    }
}

.solutions-name-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border: 1px solid #eeeeee;
    padding: 10px;

    &.active {
        background-color: #e6f7ff;
        color: #1890ff;
    }

    .solutions-item-name {
        display: inline-block;
        flex: 1;
    }

    .anticon {
        margin-left: 10px;
        cursor: pointer;
    }

    &:hover {
        background-color: #e6f7ff;
        color: #1890ff;
    }
}
