.finalcustom-head{
    display: flex;
    justify-content: space-between;

    margin-bottom: 10px;
    .go-back{
        margin-top:-5px;
        color: #000;
    }
}
.finalcustom-editorbox{
    height: 580px;
    .finalcustom-editor{
        white-space: pre-wrap;
        word-wrap: break-word;
        height: 550px !important;
        .ql-editor{
            font-size: 20px !important;
        }
    }
}
