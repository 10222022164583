.project-info {
  padding: 50px 20px;

  .project-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;

    margin-bottom: 1em;
  }

  .project-examination-info {
    margin-top: 20px;
  }

  .project-sum {
    text-indent: 2em;
    margin-bottom: 2em;
  }
  .project-info-field0 {
    text-align: right;

    span:nth-child(1) {
      display: inline-block;
      width: 240px;
      text-align: left;
    }

    span:nth-child(2) {
      display: inline-block;
      width: 160px;
      text-align: left;
    }
    span:nth-child(3) {
      display: inline-block;
      width: 140px;
      text-align: left;
    }
  }

  .project-info-field {
    text-align: right;

    span:nth-child(1) {
      display: inline-block;
      width: 240px;
      text-align: left;
    }

    span:nth-child(2) {
      display: inline-block;
      width: 300px;
      text-align: left;
    }
  }

}

.project-tools {
  display: flex;
  justify-content: center;
}

.payment-exception-remarks {
  .remark {
    margin-bottom: .5em;
    .remark-time {
      color: #888;
    }
    .remark-content {}
  }
}
