.declare_area{
    display: flex;
    justify-content: space-between;
    background-color: #FAFAFA;
}
.declare_content{
    flex: 1;
    flex-wrap: wrap;
    padding: 4px 5px;
}
.declare_button{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 230px;
}
