.labor-fee-manage {
  padding-bottom: 80px;

  .bread-area{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  .constlist-toolbar {
      .ant-form {
          width: 100%;
      }
  }

  .confirm-btn-wrap {
      display: flex;
      justify-content: space-between;
  }

  .editable-cell {
      position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .laborTipsModal {
    p {
      margin: 0;
    }
  }

  .labor-form-filter {
    .ant-picker {
      width: 100%;
    }
  }

  .labor-certificate {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;

    .ant-image, img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .close-img {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      right: 0;
      top: -10px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }

  .labor-reject-reason-modal {
    .ant-modal-confirm-title {
      text-align: center;
    }

    .ant-modal-confirm-content {
      min-height: 100px;
    }

    .labor-reject-reason {
      .labor-reject-reason-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    
        span {
          margin-right: 10px;
        }
      }
    }
  }

  .labor-step-container {
    display: flex;
    width: calc(100vw - 200px);
    background-color: #FFFFFF;
    padding: 10px 200px 0 10px;
    height: 80px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    border-top: 1px solid #f0f0f0;
    
    .step-title {
      color: #000000;
      font-weight: bold;
      white-space: nowrap;
      padding-right: 10px;
    }
  }
}

  
