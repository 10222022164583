.banner-uploader{
    width: 320px;
    height: 180px;
    >div{
        width: 320px !important;
        height: 180px !important;
    }
}

.banner-img{
    width: 100px;
}
