.data-common {
    margin: 24px 24px 0;
    h2{
        font-size: 17px;
    }
    .data-content {
        h2{
            font-weight: 600;
        }
        background-color: #fff;
        padding: 20px 24px;
        .ant-col {
            text-align: center;
            line-height: 1;
        }
    }
}
.answer-table{
    margin-bottom: 20px;
}

.choice-question{
    margin-top: 20px;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
}

.choice-question-left{
    display: flex !important;
    // justify-content: center;
    align-items: center;
}

.radio-chart{
    width: 650px;
    height: 300px;
}

.checkbox-chart{
    width: 650px;
    height: 350px;
}

.checkbox-table{
    margin-top: 30px;
}
