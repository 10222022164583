.main-content-box-padding{
    padding: 24px;
    box-sizing: border-box;
}

.questionbank_wrap{
    max-height: 100%;
    overflow: auto;
    p{
        margin: 0;
        padding: 0;
    }
    .questionbank_wrap_content{
        width: 100%;
    }
}
.main_item_wrap{
    background-color: #fff;
    width: 100%;
    >.child_container_wrap{
        padding-left: 24px;
    }
}
.item_wrap{
    width: 100%;
    height: 53px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background-color: #e6f4ff;
    border: 1px solid #f0f5ff;
    &.child_item_wrap{
        background-color: #fff;
        border-width: 0 0 1px 0;
    }
    >span{
        display: block;
        flex: 1;
    }
}
