.add-num{
    width: 30% !important;
}
.rules-icon{
    .ant-form-item-label{
        label::before{
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }
}
