.agenda-template-form {
  .color-field-wrap {
    display: flex;
    align-items: center;

    .color-field {
      display: flex;
      align-items: center;
      text-align: left;
      width: 140px;
      height: 32px;
      margin-right: 10px;
      padding: 0 5px;
      border: 1px solid rgba(217, 217, 217, 1);
    }

    .color-list {
      display: flex;
    }

    .color-previewer {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      cursor: pointer;
      border: 1px solid rgba(217, 217, 217, 1);
    }
  }
}
