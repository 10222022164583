.tree-edit-first{
    position: absolute;
    top: 0;
    >div{
        height: 27px;
        .ant-select-selector{
            height: 27px !important;
            align-items: center;
        }
    }
}
.mystyle{
    .tree-edit{
        display: block;
    }
}

.tree-edit{
    display: none;
    position: absolute;
    top: 0;
    >div{
        height: 27px;
        .ant-select-selector{
            height: 27px !important;
            align-items: center;
        }
    }
}

.edit{
    position: relative;
    .ant-tag{
    height: 19px !important;
    line-height: 19px !important;
    margin-top: 2px !important;
    margin-left: 5px !important;
}
}

