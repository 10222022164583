.main-content-box.scoring-setting-content {
    padding: 40px;
    h2 {
        padding-left: 40px;
    }

    .gray-text {
        color: #666;
        font-size: 12px;
    }

    .flex-span {
        display: flex;
        gap: 8px;
        align-items: center;

        .flex-span-1 {
            width: 20%;
        }

        .flex-span-2 {
            width: 55%;
        }

        .flex-span-3 {
            width: 20%;
        }

        & > span {
            flex-shrink: 0;
        }

        & > .ant-form-item {
            margin: 0;
        }

        input {
            flex: 1;
        }
    }
    .flex-span.margin-span {
        margin-bottom: 16px;
    }
    .margin-left-span {
        margin-left: 16px;
    }

    .scoring-setting.page-title {
        .current-title {
            flex: 1;
        }
    }

    .operation-buttons {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 40px;
        width: 100%;

        button {
            width: 120px;
        }
    }

    .group-inputNumber-div {
        display: flex;
        
        .ant-form-item:first-of-type {
            width: 100%;
        }
    }

    .form-group-item {
        .ant-radio-group {
            width: 100%;
            display: flex;
            align-items: center;

            .ant-form-item {
                margin: 0 0 0 16px;
            }
        }
    }
}

