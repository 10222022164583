.ant-layout .ant-layout-sider {
  background-color: white;
  border-right: 1px solid #eee;
}



.module-switcher {
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;

  .current-module {

  }

  .module-icon {
    font-size: 18px;
    color: #888;
    margin-right: 8px;
  }

  .module-icon-down {
    float: right;
    color: #aaa;
  }
}

.module-menu {

  .module {
    font-size: 16px;
    padding: 16px;

    .module-icon {
      display: inline-block;
      width: 28px;
      text-align: center;
      margin-right: 8px;
      color: #888;
    }

    a {
      color: #888;
    }

    .icon-active {
      float: right;
      color: #389e0d;
    }

    &.active {
      a {
        color: #333;
      }
    }
  }
}
