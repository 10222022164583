.individual-confirm-modal {
    width: calc(100vw - 100px) !important;
    max-width: 1600px !important;

    .individual-confirm-content {
        padding: 10px;
    }

    .ant-modal-content {
        min-height: 600px;
        max-height: calc(100vh - 100px) !important;
        overflow-y: auto;
    }

    .ant-modal-footer {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .labor-fee-confirm-sig {
        display: flex;
        align-items: flex-end;
        margin-top: 20px;
        flex-direction: column;

        .labor-fee-confirm-sig-content {
            display: flex;
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        .sig-name {
            margin-right: 30px;
        }
    }

    .labor-fee-project {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        margin: 40px 0 10px 0;
    }
}
