.conference-site_module {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #F0F0F0;
  margin-bottom: .5rem;

  &:not(:last-child) {
    margin-bottom: .5rem;
  }

  &:hover {
    background-color: #FAFAFA;
  }

  .site-module-visible {
    .anticon {
      margin-right: 6px;
      color: #444;
      cursor: pointer;
    }

    .invisible {
      color: #ccc;
    }
  }

  .site-module-title {
    display: flex;
    justify-content: start;
    align-items: center;
    flex: 1;

    span {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .site-module-operations {
    display: flex;
    justify-content: flex-start;
  }

  .access-btn {
    //position: absolute;
    //right: 100px;
    width: 100px;
    margin-right: 10px;

    &.text-btn {
      background: #1890ff;
      color: #ffffff;
      cursor: default;

      &:hover {
        background: #1890ff;
      }
    }
  }

  .link-btns {
    width: 340px;
    display: flex;
  }

}

.exhibition-add {
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding: 10px;

  .ant-form-item-label {
    width: 80px;
  }

  .organizationUploadWrap {
    .ant-form-item-control-input-content {
      display: flex;

      .imageWarp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 104px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        margin-right: 8px;
        padding: 8px;

        .ant-image {
          position: unset;
        }

        .ant-image-mask {
          width: 88px;
          height: 88px;
          margin: 8px;

          .ant-image-mask-info {
            width: 20px;
          }
        }
      }

      .ant-upload-picture-card-wrapper {
        width: auto;
      }
    }
  }
}
