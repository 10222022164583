.answer_rcp_wrap{
    .list_item{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border: 1px solid #ccc;
        padding: 5px 12px;
        box-sizing: border-box;
        align-items: center;
        margin-bottom: 20px;
        font-size: 16px;
        p{
            flex: 1;
            word-break: break-all;
            display:-webkit-box;         //将对象作为弹性伸缩盒子模型显示。
            -webkit-box-orient:vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
            -webkit-line-clamp:2;        //限制行数
            overflow:hidden;             //超出部分隐藏
            text-overflow:ellipsis;      //用一个省略号代替超出的内容
            margin: 0;
            padding: 0;
        }
        span{
            padding: 0 12px;
            &:first-of-type{
                margin-right: 24px;
            }
            &:last-of-type{
                display: block;
                width: 80px;
            }
        }
    }
    .select-page{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
}
