.field-group {
  margin: .5rem 0;

  .field-title {
    margin-bottom: .5rem;
  }
}
.image-box{
  .ant-upload-list {
    display: none !important;
  }
}
.uploadButton {
  float: left;
  position: absolute;
  bottom: 0px;
  text-align: left;
  margin-left: 10px;
  
  .ant-btn-default {
    width: 120px;
    margin-top: 20px;
    height: 30px;
    cursor: pointer;
  }
}

.react-editor{
  position: relative;
  max-height: 500px;
  padding: 0;
  .ql-toolbar{
    position: sticky;
    background-color: #fff !important;
    top: 0;
    z-index:1;
    border: 1px solid #ccc;
  }
}

.doc_area{
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  Input{width: 300px;}
  .path_area{
    margin-right: 20px;
  }
  .delicon{
    margin-left: 5px;
  }
  .icon1,.icon2{
  margin-top: 8px;
  }
  .icon1{
    margin-right: 5px;
  }
  .icon2{
    margin-left: 5px;
    color: #ff4d4f;
  }
}
.upload-invations-form{
  .ant-upload-list{
    display: none !important;
  }
}

.invationImg{
  margin-bottom: 15px;
  position: relative;

  .close-img {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 190px;
    top: -10px;
    color: #FFFFFF;
    cursor: pointer;
}
}

.form-subtitle-desc {
  margin:0 0 15px 16.5%;
  color: #888;
  line-height: 2;
  
}

.form-textarea-button {
  border: 1px solid rgba(0,0,0,0.2);
  padding: 5px 0 5px 20px;
  .form-textarea-button-btn {
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px
  }
}

textarea {
  text-align: justify;
  word-break: break-all;
}
