#expert-container {

  .ant-btn.dark {
    color: #444;

    &:hover {
      color: #666;
    }

  }

  .expert-fixed-toolbar {
    display: flex;
    align-items: center;
    height: 47px;
  }

  .expert-info-indicator-box {
    position: relative;
    width: 100%;
    height: 47px;
    background-color: white;
    z-index: 100;

    &.fixed {
      position: fixed;
      top: 0;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .expert-info-indicator {
    flex: 1;
    border-bottom: 1px solid #f0f0f0;

    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;

      &::before {
        border-bottom: none;
      }
    }

  }

  .expert-avatar-container {
    display: flex;
    align-items: flex-end;
  }

  .some-name-title {
    //padding-bottom: 10px;
  }

  .some-name-wrap {
    max-height: 300px;
    overflow: auto;

    .some-name-list {
      display: flex;
      border: 1px dashed #d9d9d9;
      margin: 8px 0;
      padding: 0 8px;
      align-items: center;

      .name, .mobile {
        width: 120px;
        margin-right: 10px;
      }

      .post {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: right;

    .ant-btn {
      display: inline-flex;
      padding: 0;
      align-items: center;
      height: 1em;

      &:not(:last-child) {
        margin-right: 8px;
      }

      &.dark {
        color: #444;
        &:hover {
          color: #666;
        }
      }

      .ant-icon {
        margin-right: 4px;
        line-height: 1em;
      }
    }

  }

  .unit-employment-item {
    //border: 1px solid #ddd;
    padding: 16px;
    background-color: #f7f7f9;
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .add-post-container {
    background-color: #FFF;
    border-radius: 8px;
    padding: 12px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .position {
      .ant-form-item-label {
        padding-bottom: 0;
      }

      .ant-form-item.position-item {
        margin-bottom: auto;
      }

    }

    .add-post-child {
      flex: 1;
      //padding-top: 20px;

    }

    .add-post-index {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #ddd;
    }

  }

  .contact-container {
    position: relative;
    padding: 16px;
    background-color: #f7f7f9;
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .actions {
      position: absolute;
      //padding: 16px 0 16px 16px;
      top: -4px;
      right: -10px;

      .ant-btn-icon-only {
        width: auto;
      }
    }

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item {
      margin-bottom: unset;
    }
  }

  .fixed-foot-toolbar {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 24px 10px 244px;
    background-color: white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .expert-status {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .expert-level-instruction {
    border: 1px solid #91d5ff;
    //background-color: #e6f7ff;
    border-radius: 4px;
    padding: 8px;
    margin-top: 10px;
    max-height: 200px;
    overflow: auto;

    /* 自定义滚动条 */
    /* width */
    &.vertical-scrollbar::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &.vertical-scrollbar::-webkit-scrollbar-track {
      background: #91d5ff;
      border-radius: 8px;
    }

    /* Handle */
    &.vertical-scrollbar::-webkit-scrollbar-thumb {
      background: #40a9ff;
      border-radius: 8px;
    }

    /* Handle on hover */
    &.vertical-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #1890ff;
    }

    .expert-level {
      color: #333;
    }
  }

}
