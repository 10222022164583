.members-tabs {
    .ant-badge {
        font-size: 16px;
    }

    .ant-tabs-tab:hover {
        .ant-badge {
            color: #40a9ff;
        }
    }

    .ant-tabs-tab-active {
        .ant-badge {
            color: #1890ff;
        }

        &:hover {
            .ant-badge {
                color: #1890ff;
            }
        }
    }
}
