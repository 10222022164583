.fieles-choice {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 4px;
    border-radius: 2px;
    cursor: pointer;
    line-height: 26px;

    .ant-checkbox-wrapper {
        width: 190px;
    }

    .field-name {
        width: 160px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .field-description {
        width: 200px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .field-required {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ant-form-item {
        margin: 0;
    }

    &:hover {
        background-color: #e6f4ff;
    }
}

.field-list-table-title {
    span {
        height: 40px;
        line-height: 40px;
        display: inline-block;
        background: #fafafa;
        padding: 0 5px;
    }

    span:nth-child(1) {
        width: 190px;
    }

    span:nth-child(2) {
        width: 160px;
        text-align: center;
    }

    span:nth-child(3) {
        width: 200px;
        text-align: center;
    }

    span:nth-child(4) {
        width: 150px;
    }

    span:nth-child(5) {
        width: 100px;
        padding: 0 18px;
    }
}
