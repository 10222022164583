.signin-setting {
    .setting-info {
        margin-bottom: 50px;

        .ant-form-item-label {
            width: 125px;
        }
    }
    .setting-type{
        .setting-type-title::before{
            content: '*';
            color: red;
        }
        .setting-type-desc{
            margin-top: 22px;
            font-size: 12px;
            color: #999999;
            .setting-type-desc-red{
                color: red;
            }
        }
    }
    .setting-table {
        max-width: 500px;
        margin-top: 15px;
    }

    .setting-footer {
        margin-left: 130px;
        margin-top: 50px;
        .ant-btn {
            margin-right: 30px;
        }
    }
}
