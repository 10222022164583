.user-open-icon {
  margin-left: 5px;
  color: #1a73e8 !important;
}

.user-open-box {
  color: #1a73e8;
  cursor: pointer;
}

.refresh-icon {
  cursor: pointer;
  margin-left: 5px;
  color: #aaaaaa !important;
}

// .refresh-icon:hover{
//     color: #1a73e8 !important;
// }

.display-none-dom {
  display: none !important;
}

.identity-control-space {
  .ant-form-item-label {
    width: 350px;
  }

  .ant-form-item-control {
    .ant-form-item-control-input {
      @media (min-width: 1400px) {
        width: 120px;
      }
      @media (min-width: 1759px) {
        width: 130px;
      }
    }

    .ant-select {
      @media (min-width: 1200px) {
        width: 110px !important;
      }
      @media (min-width: 1400px) {
        width: 120px !important;
      }
      @media (min-width: 1759px) {
        width: 137px !important;
      }
      width: 140px !important
    }
  }

  .ant-space-item:last-child {
    position: absolute;
    @media (min-width: 1200px) {
      left: 205px;
    }
    @media (min-width: 1400px) {
      left: 220px;
    }
    @media (min-width: 1759px) {
      left: 240px;
    }
    left: 245px;
  }
}

//.ant-popover-inner-content {
//  padding: 0 !important;
//}

.field-change-box {
  // width: 250px;
  overflow: hidden;
}

//.ant-popover-inner {
//  width: 250px;
//}

//.ant-popover {
//  margin-top: 20px !important;
//}

.not-show {
  display: none;
}

.field-change-header {
  border-bottom: 1px solid #eee;
  padding: 7px 16px;
  overflow: hidden;

  .field-change-header-left {
    float: left;
  }

  .field-change-header-right {
    float: right;
    color: #1a73e8;
    cursor: pointer;
  }
}

.field-change-bottom {
  padding: 10px 16px !important;
}
