.create-box{
    min-height: calc(100vh - 105px);
    .survey-action{
        text-align: center;
        span{
            font-size: 22px;
        }
        .action-area{
            margin-top: 20px;
            .action-item{
                cursor: pointer;
                margin-top: 15px;  
            }
        }
    }
}

.page-title{
    display: flex;


    .page-left{
        display: flex;
    }
    .page-center{
        flex: 1;
        
    }
    .page-right{
        Button{
            margin-right: 10px;
        }
    }
}

.process{
    margin: 15px 10px;
    
}
.survey-title{
    overflow: hidden;
    white-space: nowrap;         //让文本不换行，在同一行里面
    overflow: hidden;           //让超出的范围进行隐藏
    text-overflow: ellipsis;
}

