.mark-change-modal {
    &-content {
        display: flex;
        align-items: center;

        span {
            white-space: nowrap;
        }
    }
}

.step-container {
    margin: 0 auto;
    max-width: 600px;
    padding: 24px 24px 0;
}
