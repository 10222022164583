@import '~antd/dist/antd.less';

body {
  background-color: #FAFAFA;
}

.App > .ant-layout {
  min-height: 100%;
}

.main-content-box {

  .ant-picker-input > input {
    font-size: 12px !important;
    padding-top: 1.8px !important;
    padding-bottom: 1.8px !important;
  }

  .ant-picker {
    padding: 4px 8px !important;
  }
}

.page-title {
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  background-color: white;
  padding: 0 24px;
  border-bottom: 1px solid #eee;

  //box-shadow: 0 2px 4px rgba(0, 0, 0, .075);

  .back {
    display: inline-block;
    width: 32px;
    text-align: center;
    margin-right: .5rem;
  }

  .current-title {
  }

  .current-subtitle {
    em {
      font-style: normal;
    }

    color: #f5222d;
    font-size: 16px;
    margin-left: 1rem;
    width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}


.main-content-box {
  //padding: 24px;
  margin: 24px 24px 0;
  background-color: white;
  border: 1px solid #eee;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, .075);
  min-width: 375px;

  &:last-child {
    margin-bottom: 24px;
  }

  .filter-form-box {
    padding: 24px 24px 0;
    border-bottom: 1px solid #eee;
  }

  .filter-form-buttons {
    text-align: right;
    margin-bottom: 24px;
  }

  .main-content-title {
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-bottom: 1px solid #eee;
  }

  .main-content-body {
    position: relative;
    height: 100%;
    padding: 24px;
  }

  &.no-bg-color {
    background-color: transparent;
  }

  .content-block {
    padding: 24px;
    background-color: white;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .content-block-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .content-block-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #444;
    }

    .content-block-toolbar {
      display: flex;
      align-items: center;
    }

  }

  .main-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .main-tools {

    }

    .sub-tools {

    }
  }

  .table-caption {
    padding-bottom: 20px;
    overflow: hidden;

    .table-title {
      display: inline-block;
      font-size: 18px;
    }

    .table-toolbar {
      float: right;

      .ant-btn {
        //margin-left: .5rem;
      }
    }
  }

  .operation-btns {
    .ant-divider.ant-divider-vertical {
      margin: 0;
    }

    .more-operation {
      color: #666;
      font-size: 14px;
      vertical-align: middle;

      &:hover {
        color: #444;
      }
      svg {
        font-weight: bold;
      }
    }
  }

}

.page-form-foot-operations {
  padding-top: 24px;
  margin-bottom: 0;
}

.page_title_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
  padding: 0 24px;
  border-bottom: 1px solid #eee;
  position: relative;

  .page-left {
    display: flex;
    flex: 1;
    max-width: 550px;

    .share-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      box-sizing: border-box;
    }
  }

  .page-center {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .page-right {
    Button {
      margin-right: 10px;
    }
  }

  .back {
    display: inline-block;
    width: 32px;
    text-align: center;
    margin-right: .5rem;
  }
}

// 子 Form.Item 为必填时，父 Form.Item 必选样式
// <Form.Item className="required"
.ant-form-item.required .ant-form-item-label > label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}



.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 16px 5px;
}

.ant-upload {
  img {
    height: 100%;
  }
}

.operate-style {
  color: #1890ff;
  margin-right: 10px;
  cursor: pointer;
}

.delete-style {
  color: #df2e1e;
  cursor: pointer;
}

.form-container {
  padding: 24px;
}

// 表单页面小标题，用于表单字段分类展示
.form-subtitle {
  font-size: 16px;
  font-weight: 600;
  padding: 24px 24px 16px;
  color: #666;
  display: flex;
  align-items: center;

  //&:first-child {
  //  padding-top: 0;
  //}

  .main {
    flex: 1;
  }
  .tools {
  }
}

.box-hover-shadow {
  border-radius: 4px;
  border: 1px solid #e3e4e6;
  transition: box-shadow .3s;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.08),0 2px 4px 0 rgba(0,0,0,.16);
  }
}

.store-cursor {
  background-color: #FFF7E6;
}
.ant-table-tbody > tr.store-cursor > td.ant-table-cell-row-hover {
  background-color: #FFF7E6;
}
.store-cursor .ant-table-cell-fix-left,
.store-cursor .ant-table-cell-fix-right {
  background-color: #FFF7E6;
}

.tooltip-icon {
  color: #999;
  cursor: pointer;
  margin-left: 4px;

  &:hover {
    color: #666;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.back {
  color: #1890ff;
  cursor: pointer;
}

.close-status {
  color: #f4b400;
}

.open-status {
  color: #0f9d58;
}

@font-face {
  font-family: 'dragicon';
  src: url('./static/assets/dragfont/iconfont.ttf') format('truetype');
}

.dragicon {
  font-family: "dragicon" !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_survey_content_wrap {
  min-width: 1200px;
  max-height: 100%;

  .sidebar {
    max-height: 100%;
    overflow: auto;
  }

  #wj_template_wrap {
    overflow: auto;
  }

  #wj_survey_wrap {
    overflow: auto;
  }
}


/* 自定义垂直滚动条 */
.vertical-scrollbar {
  overflow-x: hidden;
  overflow-y: scroll;
}

/* 自定义滚动条 */
/* width */
.vertical-scrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.vertical-scrollbar::-webkit-scrollbar-track {
  background: #E0E0E0;
  border-radius: 5px;
}

/* Handle */
.vertical-scrollbar::-webkit-scrollbar-thumb {
  background: #C5C5C5;
  border-radius: 5px;
}

/* Handle on hover */
.vertical-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #CCC;
}


/* 自定义横向滚动条 */
.crosswise-scrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
}

/* 自定义滚动条 */
/* width */
.crosswise-scrollbar::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.crosswise-scrollbar::-webkit-scrollbar-track {
  background: #E0E0E0;
  border-radius: 5px;
}

/* Handle */
.crosswise-scrollbar::-webkit-scrollbar-thumb {
  background: #C5C5C5;
  border-radius: 5px;
}

/* Handle on hover */
.crosswise-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #CCC;
}
.tox-notifications-container{
  display: none;
}

.red-asterisk-before {
  position: relative;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.red-asterisk-after {
  position: relative;

  &::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.customized-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
