.facade-action {
    .facade-header {
        margin-top: 5px;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        .ant-switch-inner{
            font-size: 14px !important;
        }
    }

    .pc-width {
        width: 80%;
        height: 100px;
        padding: 3px;
        border: 2px solid transparent;
        &:hover {
            border: 2px solid #1a73e8;
        }
    }

    .app-width {
        width: 80%;
        height: 92%;
        padding: 0px;
        border: 2px solid transparent;
        &:hover {
            border: 2px solid #1a73e8;
        }
       
    }

    .app-box {
        display: flex;
        height: 170px;

    }

    .app-cover {
        display: flex;
        border: 1px solid #000;
        box-sizing: border-box;
        width: 33%;
        margin: 8px;
        height: 90%;
        padding: 3px;
        justify-content: center;
        align-items: center;
        margin-top: 3px;
        .add-icon{
            font-size: 12px;
        }
    }

    .pc-cover {
        display: flex;
        border: 1px solid #000;
        box-sizing: border-box;
        width: 80%;
        margin-left: 23px !important;
        margin-top: 10px;
        height: 100px;
        justify-content: center;
        align-items: center;
    }

    .ant-upload-list-item-name {
        display: none;
    }

    .ant-upload-text-icon {
        display: none;
    }

    .ant-upload-list-item-card-actions {
        display: none;
    }

    .ant-upload-span {
        display: none;
    }
    .ant-upload-list{
        display: none;
    } 
    .ant-upload-list-text{
        display: none;
    }
}

.selected {
    width: 80%;
    height: 100px;
    padding: 4px;
    border: 3px solid #1a73e8;
}

.app-selected {
    width: 80%;
    height: 92%;
    padding: 4px;
    border: 3px solid #1a73e8;
}

.app-img-box {
    position: relative;
    width: 32%;
    box-sizing: border-box;

    .del-app-img {
        position: absolute;
        right: 8px;
        top: 6px;
        font-size: 16px !important;
        font-weight: bolder !important;
        color: #000;
        cursor: pointer;
        display: none;
        overflow: hidden;
    }
    &:nth-child(3):hover {
        .del-app-img{
            display: block;
            overflow: hidden;
        }
    }
}

.pc-img-box {
    position: relative;
    width: 98%;
    border: 1px solid transparent;
    box-sizing: border-box !important;

    .del-app-img {
        position: absolute;
        right: 8px;
        top: -25px;
        font-size: 16px !important;
        font-weight: bolder !important;
        color: #000;
        cursor: pointer;
        display: none;
        overflow: hidden !important;;
    }
    &:nth-child(3):hover {
        .del-app-img{
            display: inline-block;
            overflow: hidden;
        }
    }
}
.app-header-box{
    position: relative;
    width: 98%;
    border: 1px solid transparent;
    box-sizing: border-box !important;

    .del-app-img {
        position: absolute;
        right: 8px;
        top: -25px;
        font-size: 16px !important;
        font-weight: bolder !important;
        color: #000;
        cursor: pointer;
        display: none;
        overflow: hidden !important;;
    }
    &:nth-child(4):hover {
        .del-app-img{
            display: inline-block;
            overflow: hidden;
        }
    }
}

.pc-header-box{
    position: relative;
    width: 98%;
    border: 1px solid transparent;
    box-sizing: border-box !important;

    .del-app-img {
        position: absolute;
        right: 8px;
        top: -25px;
        font-size: 16px !important;
        font-weight: bolder !important;
        color: #000;
        cursor: pointer;
        display: none;
        overflow: hidden !important;;
    }
    &:nth-child(4):hover {
        .del-app-img{
            display: inline-block;
            overflow: hidden ;
        }
    }
}
.upload-text{
    color: #a6a6a6;
    font-size: 12px;
    word-break: break-all;
}
