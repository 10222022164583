.home-content-box{
    margin: 24px 24px 0;
    border: 1px solid #eee;
}
.index h2{
    font-weight: 600;
    font-size: 18px;
}

.all-margin{
    margin-bottom: 40px;
}

.tendency{
    h4{
        margin-bottom: 10px;
        margin-left: 15px;
        padding-top: 10px;
    }  
    .outside-col-left{
        padding-left: 0 !important;
    } 
    .outside-col-right{
        padding-right: 0 !important;
    } 
    .ant-row{
        margin-left: 0 !important; 
        margin-right: 0 !important;
    } 
    .ant-card-body{
        padding: 0;
        height: 152px;
        .ant-row{
            // padding-left: 20px;
            text-align: center;
        }
        p{
            color: #999;
            padding-bottom: 8px;
            margin: 0;
            font-size: 13px;
        }
        .tendency-num{
            color: #000;
            font-size: 15px;
        }
    }
}
.shortcuts{
    margin-top: 20px;
    padding: 0 30px;
    ul{
      list-style: none; 
      padding: 0;
      display: flex;
      justify-content: space-between;
    }
    li{
        height: 100px;
        width: 180px;
        background-color: rgb(250, 249, 249);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 30px;
        }
        p{
            margin-bottom: 0;
        }
    }
    a{
        text-decoration: none;
        color: black;
    }
    a:hover{
        color: black;
    }
    
}
.data-statistics{
    .gutter-row{
        text-align: center;
    }
    p{
        color: #999;
        margin: 0;
    }
    .tendency-num{
        color: #000;
        font-weight: 600;
        font-size: 20px;
    }
    .ant-card-body{
        padding-top: 15px;
        padding-bottom: 30px;
    }
}

.index-module-top{
    height: 27px;
    line-height: 27px;
    display: flex;
    margin-bottom: 20px;
    h2{
        margin-right: 8px;
    }
    span{
        font-size: 12px;
    }
    p{
        margin-left: 20px;
        font-size: 12px;
        color: #999;
    }
}
.usage-statistics{
    .index-module-top{
        margin-bottom: 0;
    }
}
.echarts-module{
    height: 400px;
    width: 971px;
    margin-top: -10px;
    >div{
        width: inherit;
        box-sizing: border-box;
    }
}
