.topics_item_wrap{
    background-color: #fff;
    width: 100%;
    z-index: 9999;
    margin-top: 15px;
    >.child_container_wrap{
        padding-left: 24px;
    }
}
.topics_wrap{
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    z-index: 9999;
    &.topics_child_item_wrap{
        background-color: #fff;
        margin-top: 10px;
        border-width: 0 0 1px 0;
        z-index: 9999;
    }
    >span{
        display: block;
        flex: 1;
    }
}
