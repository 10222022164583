.edit-solution {
    margin-top: 20px !important;
    .no-require-info {
        margin: 30px 0 6px;

        p:first-of-type {
            width: 75%;
            margin-left: 16.666%;
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
                display: inline-block;
                width: calc(50% - 106px);
                height: 1px;
                background-color: #EEEEEE;
            }

            span {
                display: inline-block;
                width: 112px;
                height: 19px;
                font-size: 14px;
                color: #333333;
                line-height: 19px;
            }
        }

        p:last-of-type {
            width: 100%;
            text-align: center;
            font-size: 12px;
            color: #999999;
            line-height: 16px;
        }
    }

    .copyright-notice {
        padding: 0;
    }

    .solution-cover {
        margin-bottom: 10px;
        width: 240px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-image {
            width: 240px;
            height: 135px;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }

    .solution-attachment {
        display: flex;
        align-items: center;

        .anticon {
            width: 25px;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .docIcon {
            line-height: 16px;
            margin-right: 5px;
        }

        .anticon {
            width: 20px;
        }

        a {
            margin-right: 10px;
        }
    }

    .solution-video {
        margin-top: 10px;

        .anticon {
            width: 20px;
        }

        a {
            margin-right: 10px;
        }
    }

    .images-add {
        margin-bottom: 10px;
        padding: 10px;
        padding-left: 0;
        height: 140px;

        .image-container {
            width: 240px;
            height: 135px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-image {
                width: 240px;
                height: 135px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .anticon {
            width: 20px;
        }
    }

    .docs-add {
        margin-bottom: 10px;
        padding-left: 0;

        .anticon {
            width: 20px;
        }

        .ant-input {
            margin-right: 5px;
        }


        a {
            margin-right: 10px;
        }
    }

    .show-info {
        margin: 0;
        color: #ff4d4f;
    }

    .docIconBox {
        display: flex;
        align-items: center;

        .docIcon {
            margin-right: 5px;
            line-height: 16px;
        }
    }

    a {
        color: initial;
    }

    .footer-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        position: sticky;
        background: #FFFFFF;
        padding: 10px 5px;
        bottom: 0;
        z-index: 999;
        border-top: 1px solid #e3e3e3;
    }
}

.copyrightModal {
    .ant-modal-content .ant-modal-confirm-btns {
        text-align: center;
    }
}

