.to-live-replays{
    color: black !important;
}

.add-to-live-replays{
    border-bottom: 1px solid #1890ff;
}

.live-tab{
    .ant-tabs-nav{
        margin-bottom: 0;
    }
}

.live-table-title{
    position: relative;
}
.live-post-data{
    position: absolute !important;
    top: 5px;
    right: 0;
}
