.signin-detail {
    p {
        margin: 0;
    }

    .detail-header {
        .ant-form {
            width: 100%;
        }
    }

    .detail-message {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .signin-tip {
            padding-left: 20px;
            color: #00000072;
        }
    }

    .edit-btn {
        padding-left: 0;
    }

}

.signInModal {
    .ant-input-number {
        width: 100% !important;
    }
    .ant-form-large .ant-form-item-label > label{
        white-space: normal;
        height: auto;
        min-height: 40px;
    }
}
