.module-free-body {

  min-height: 300px;

  .main-tools {
    display: block;
    margin-bottom: 30px;

    .ant-btn {
      padding: 4px 30px !important;
    }

  }

  .main-editor {
    align-items: flex-start;
    margin-bottom: 30px;

    .editor-label {
      width: 80px;
      flex-shrink: 0;
      display: block;
    }

    .free-tips {
      display: block;
      color: #999999;
      margin-bottom: 20px;
    }

    .ql-container {
      min-height: 300px;
    }
  }

  .main-frees {
    margin-bottom: 100px;

    .free-input {
      width: 400px !important;
    }
  }

  .main-schedule {

    .schedule-box {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      > span {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }

    .schedule-content {
      margin-bottom: 30px;

      .schedule-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        > span {
          flex-shrink: 0;
          margin-right: 10px;
        }
      }

      .schedule-table-head {
        width: 100%;
        display: flex;
        padding: 16px 0;
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;

        & > span {
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          padding: 0 5px;
          box-sizing: border-box;
        }

        .schedule-head-start {
          width: 130px;
          //text-align: center;
        }

        .schedule-head-end {
          width: 130px;
          //text-align: center;
        }

        .schedule-head-name {
          width: 200px;
          //text-align: center;
        }

        .schedule-head-text {
          flex: 1;
          //text-align: center;
        }

        .schedule-head-settings {
          width: 140px;
          //text-align: center;
        }

      }
    }

    .schedule-table-tr {
      display: flex !important;
      border-bottom: 1px solid #f0f0f0;
      padding: 12px 0;

      &:hover {
        background: #fafafa;
      }

      .schedule-table-start {
        width: 130px;
        //text-align: center;
        padding: 0 5px;
        box-sizing: border-box;
      }

      .schedule-table-end {
        width: 130px;
        //text-align: center;
        padding: 0 5px;
        box-sizing: border-box;
      }

      .schedule-table-name {
        width: 200px;
        //text-align: center;
        padding: 0 5px;
        box-sizing: border-box;
      }

      .schedule-table-text {
        flex: 1;
        min-width: 200px;
        //text-align: center;
        padding: 0 5px;
        box-sizing: border-box;
        word-break: break-all;
      }

      .schedule-table-settings {
        width: 140px;
        //text-align: center;
        box-sizing: border-box;
      }

    }

  }


  .free-footer {

    .ant-btn {
      padding: 4px 30px !important;
    }

  }

}


