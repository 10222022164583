.channelBox{
  p{
    margin:0;
  }
  .channelReg{
    display: inline-block;
    width:60px;
  }
}
.channelColor{
  color:orange;
}
.tableStyle{
  p{
    margin:0;
  }
  span{
    display: inline-block;
    width:60px;
  }
}
.formMarginLeft{
  margin-left:20px;
}
.main-content-time{
  display: inline-block;
}
.main-content-time2{
  display: inline-block;
  margin-left:8px;
}

