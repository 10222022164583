.page-title {
    display: flex;

    .page-left {
        display: flex;
        width: 400px;

        .share-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .page-center {
        flex: 1;
        text-align: center;
        margin-left: -260px;
        justify-content: center;
    }

    .page-right {
        Button {
            margin-right: 10px;
        }
    }
}

.share_survey_wrap {
    margin: 24px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 24px;

    .head {
        margin-bottom: 24px;

        h2 {
            font-size: 16px;
            position: relative;
            padding-left: 10px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 4px;
                height: 16px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                background-color: #1890ff;
            }
        }
    }

    .row_item_wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
    }

    .col_item_wrap {
        flex: 1;
        height: 260px;
        margin-right: 24px;
        padding: 24px !important;
        box-sizing: border-box;
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        h3 {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 400;
            color: #999999;
            line-height: 25px;
        }

        &:first-of-type {
            align-items: flex-start;

            h3 {
                text-align: center;
                display: block;
                width: 100%;
                margin-bottom: 47px;
            }

            p {
                width: 100%;
                padding: 10px;
                // background-color: #eee;
            }
        }

        &:last-of-type {
            margin-right: 0;
        }

        &.noStyle {
            opacity: 0;
        }

        &.haibao_wrap {
            height: 800px;

            .haibao_wrap_main {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;

                .haibao_item {
                    width: 350px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 24px;

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .ant-upload.ant-upload-select-picture-card,
                    .ant-upload-list-picture-card-container {
                        border: none;
                        width: 342.6px;
                        height: 500px;
                        margin-right: -0.5px;
                    }

                    .haibao_upload_icon {
                        cursor: pointer;
                    }

                    .haibao_item_main {
                        height: 624px;
                        box-shadow: 0 0 5px 5px #eee;
                        margin-bottom: 24px;

                        >div {
                            width: 100%;
                            display: flex;
                            height: 124px;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 18px 20px;
                            box-sizing: border-box;

                            >.left {
                                flex: 1;
                                padding-right: 10px;
                                box-sizing: border-box;

                                p {
                                    // background-color: #fff;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    word-break: break-all; //增加允许在单词内换行
                                    color: #333;
                                    height: 40px;
                                    font-size: 16px;
                                    line-height: 20px;
                                    margin: 0;
                                    padding: 0;
                                }

                                span {
                                    color: #ccc;
                                    display: block;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                    margin-top: -18px;
                                    margin-bottom: 16px;
                                    overflow: hidden;
                                }
                            }

                            .qrCode_wrap {
                                width: 80px;
                                height: 80px;
                            }
                        }

                        >img {
                            width: 100%;
                            height: 500px;
                        }
                    }
                }
            }
        }

        .erweima_wrap {
            width: 114px;
            height: 114px;
            margin-bottom: 10px;

            >img {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.share_survey_modal_wrap {
    width: 1000px !important;

    .ant-modal-content {
        width: 1000px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .ant-modal-body {
        flex: 1;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}

.haibao-logo {
    margin-top: 10px;
    width: 70px;
    height: 18px;
}


.url_button_area,
.qrcode_button_area {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .url_button_left {
        Button {
            margin-right: 20px;
        }
    }
}
.url_button_area{
    margin-top: 74px;
}
.url_area{
    border: none;
}


.qrcode_button_area {
    justify-content: space-around;
    margin-top: 19px;
}


.enjoy_avatar {
    width: 50px;
    height: 50px;
    margin-top: 5px;
}

.share_wexin {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;

    .wexin_message {
        width: 310px;
        height: 108px;
        margin-left: 10px;
        background: #F5F6F8;
        border-radius: 4px;
        display: flex;

        p {
            margin: 0;
        }

        .share_title {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            width: 100%;
            max-height: 50px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            display: -webkit-box ;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 9px 10px 0 10px;
            margin-bottom: 7px;
        }

        .share_dire {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 15px;
            width: 100%;
            max-height: 30px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            display: -webkit-box ;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding: 0px 1px 16px 10px;
        }
    }

    .weixin_share {
        width: 64px;
        height: 64px;
        margin-top: 18px;
        margin-right: 10px;
    }

    .wexin_message_left {
        flex: 1;
        height: 108px;
    }
}
