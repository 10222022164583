.reference-footer{
    display: flex;
    justify-content: center;
}
.reference-header{
    display: flex;
    justify-content: flex-start;
    margin-bottom: -20px;
}
.preview-title{
    margin-top: 8px;
}
.preview-center{
    flex: 1;
    display: flex;
    justify-content: center;
    margin-left: -45px;
}

.preview-action{
        text-align: center;
        font-size: 12px;
        margin-right: 40px;
        line-height: 12px;
        cursor: pointer;
    .preview-icon{
        font-size: 25px;
        margin-bottom: 5px;
    }
    p{
        margin: 0;
    }
}

.quote-mobile-box{
    width: 80%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .mobile-final{
        width:400px !important;
        height:90%;
        position: relative;
        background-image: url('../../../../../static/images/phone.png');
        background-size: 100% 100%;
        display: flex;
        margin-left: 230px;
        justify-content: center;
        .modal-content{
            width: 90%;
            height: 96%;
            transform: scale(1.02);
            margin-top:10px;
            position: relative;
            border-radius: 38px; 
            -moz-border-radius: 10px;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            background-color: black;
            .iframe-width{
                width: 100%;
                height: 100%;
                border: 1px soild #fff;
                // pointer-events: none;
               
            }
        }
        .phone-header{
            width: 44%;
            height: 24px;
            position: absolute;
            z-index: 1;
            left: 26%;
            top: 0.8%;
        }
    }
    .survey-answer{
        opacity: 0;
    }
}

.quote-iframe-width{
    height: 500px;
    width: 100%;
    border: none;
    // pointer-events: none;
}
.templete-preview{
    margin-top: -70px !important;
}
