.notice-detail {
  
    .notice-title {
        width: 100%;
        text-align: center;
        font-size: 30px;
    }

    .time{
        width: 100%;
        text-align: center;
    }

    .ant-modal-body {
        height: 34.375rem;
    }

    .ql-container {
        height: 25rem;
        border: none;
    }

    .ql-container.ql-snow {
        border: none;
    }
}

