.report_charts_wrap{
    max-height: 100%;
    min-width: 1200px;
    background-color: #fff;
    .page_title_wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #fff;
        height: 48px;
        line-height: 48px;
        font-size: 20px;
        padding: 0 24px;
        border-bottom: 1px solid #eee;
        position: relative;
        .page-left{
            display: flex;
            flex: 1;
            .share-name{
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-left: 10px;
                box-sizing: border-box;
            }
        }
        .page-center{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
        .page-right{
            Button{
                margin-right: 10px;
            }
        }
    }
    .main_content{
        height: 100%;
        background-color: #fff;
        margin: 24px;
        box-sizing: border-box;
        .render_wrap{
            padding: 24px;
            box-sizing: border-box;
            height: 100%;
            width: 1200px;
            margin: 0 auto;
            overflow: auto;
        }
        .sdclsnvkjnskjdv{
            font-size: 30px;
            line-height: 40px;
            font-weight: 700;
        }
        .head{
            margin-top: 10px;
            .input_title{
                min-height: 65px;
                line-height: 65px;
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 10px;
                box-sizing: border-box;
            }
            h1{
                min-height: 65px;
                line-height: 65px;
                font-size: 30px;
                font-weight: 700;
                margin-bottom: 10px;
                text-align: center;
            }
        }

        .data_content{
            .data_content_btn{
                border-color: #1890ff;
                color: #1890ff;
                border-radius: 4px;
            }
            .data_content_main{
                min-height: 400px;
                background-color: #fff;
                width: 100%;
            }
        }

        .footer{
        }

        .img_wrap{
            text-align: center;
            margin-bottom: 24px;
            .upload_img_wrap{
                width: 200px;
                height: 112px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border: 1px dashed #eee;
                background-color: rgb(244, 242, 242);
                >div{
                    margin-top: 20px;
                }
            }
        }

        .text_center{
            text-align: center;
        }

        .my_self_input{
            border-color: transparent;
            &:hover{
                border: 1px dashed #1890ff;
            }
            &:focus{
                border-style: solid;
                border-color: #1890ff;
            }
        }
    }
}
