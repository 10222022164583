.header-title{
    background-color: #fff;
    display: flex;
    padding: 30px;
    font-weight: bold;
    font-size:28px;
    justify-content: space-around;
    border-bottom: 1px solid #eee;

}
.basic-content{
    background: #fff;
    // text-align: center;
}
.basic-content-div{
    // background: pink;
    width: 500px;
    margin-bottom: 20px; 
    text-align: center;
    padding-left: 50px;
    // background: pink;
    // background-color:#f6f6f6;
    // border: 1px solid black;
    padding: 20px;
    

}
.basic-page-title{
    height: 48px;
    line-height: 48px;
    font-size: 20px;
    background-color: white;
    padding: 0 24px;
    border-bottom: 1px solid #eee;
}
// .basic-content{
//     margin: 24px 24px 0;
// }
.basic-wrap{
    background-color: #f0f2f5;
    .back{
        margin-right: 0.5rem;
    }
    .header-title{
        padding: 30px 30px 15px;
        tr{
            span{

                font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 18px;
                color: #333;
            }
        }
    }
    .basic-content-div{
        box-shadow: 0 0 15px 5px #eee;
        button{
            margin-right: 20px;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    >.basic-content{
        margin: 24px;
        >.basic-content{
            padding: 30px 30px 20px;
        }
    }
}
