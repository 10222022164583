.custom-create {
    h1 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        border-left: 4px solid #1a73e8;
        padding-left: 12px;
    }

    .create-area {
        width: 100%;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        .create-center {
            p {
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #222222;
                line-height: 22px;
            }

            .create-button {
                width: 125px;
                height: 30px;
                background: #1a73e8;
                border-radius: 4px;
                color: #FFFFFF;
            }
        }

    }

}

.question-template {
    margin-top: 40px;

    .template-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        h1 {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            border-left: 4px solid #1a73e8;
            padding-left: 12px;
        }

        .ant-input-affix-wrapper {
            width: 232px !important;
            height: 29px;
            border-radius: 15px;
            border: 1px solid #DBDBDB;
        }
    }

    .template-item {
        height: 150px;
        border-radius: 10px;
        transition:0.5s all;
        &:hover{
            border:1px solid #1a73e8;
            box-shadow: 2px 2px 5px #000;
        }
    }

    .template-title {
        height: 45px;
        overflow: hidden;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        line-height: 22px;
        text-align: left;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .template-load{
        margin-top: -20px;
    }
    .template-operate {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
}
.page-area{
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    .page-total{
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
}
