.question_modal_wrap{
    min-width: 1200px;
    #question{
        min-height: 400px;
        .ant-form-item-with-help{
            margin-bottom: 24px;
        }
    }
    .qesRadio{
        display: flex;
        flex-wrap: wrap;

    }
    .radio_group_wrap{
        flex-wrap: wrap;
        .ant-radio-button-wrapper-checked{
            background-color: #40a9ff;
            color: #fff;
            border-color: #40a9ff;
            
        }
        .ant-radio-button-wrapper{
            &:hover{
                background-color: #40a9ff;
                color: #fff;
                border-color: #40a9ff;
            }
        }
    }
    .add_title_wrap{
        background-color: rgb(239, 239, 239);
    }
    .more_title_wrap{
        .ant-form-item-label{
            >label{
                // height: 40px;
            }
        }
        .more_title_content{
            line-height: 32px;
            font-size: 14px;
            input{
                border-width: 0 0 1px 0;
                margin: 0 5px;
                line-height: 24px;
                height: 24px;
                box-sizing: border-box;
                font-size: 14px;
                border-color: #333;
                max-width: 800px !important;
            }
        }
    }
    .small_size_font_wrap{
        font-size: 12px;
    }
    .no_form_item_mab{
        .ant-form-item{
            margin-bottom: 0;
        }
    }
    .selete_item_wrap,.selete_question_item_wrap{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 16px;
        background-color: #fff;
        .ant-form-item{
            flex: 1;
            margin-bottom: 0;
            &.ant-form-item-with-help{
                margin-bottom: 0 !important;
            }
            .ant-form-item-control-input-content{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

        }
        button{
            margin: 0 10px;
            
        }
        >span.close_icon{
            display: block;
            height: 32px;
            line-height: 32px;
        }
    }
}
.selete_item_wrap{
    z-index: 1001;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 16px;
    background-color: #fff;
    .ant-form-item{
        flex: 1;
        margin-bottom: 0;
        &.ant-form-item-with-help{
            margin-bottom: 0 !important;
        }
        .ant-form-item-control-input-content{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
    }
    button{
        margin: 0 10px;
    }
    >span.close_icon{
        display: block;
        height: 32px;
        line-height: 32px;
    }
}

.selete_question_item_wrap{
    z-index: 1001;
    .selete-action{
        display: none;
    }
    &:hover .selete-item{
        border: 1px dashed  #1a73e8 !important;
    }
    &:hover .selete-action{
        display: block;
    }
}


.customize_toolbar{
    margin-bottom: -10px;
}

.more_show_area{
    .ql-container{
        border: none;
        min-height: 32px;
    }
}
.upload_img{
    border: 1.5px dashed #cccccc;
    padding: 2px;
    margin-left: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 104px;
    height: 104px;
    .upload_img_box{
        height: 100%;
        width: 100%;
        display: flex;
        background-color:rgba(128, 128, 128, 0.2);
        align-items: center;
        div{
            width: 100%;
            text-align: center;
            user-select: none;
            color: #666666;
        }
        .upload-title {
            font-size: 16px;
            margin-bottom: 10px;
            color: #000000;
        }
    }
}

.sign_area{
    border: 1.5px dashed #cccccc;
    padding: 2px;
    margin-left: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 140px;
    .sign_area_box{
        height: 100%;
        width: 100%;
        display: flex;
        background-color:rgba(128, 128, 128, 0.2);
        align-items: center;
        div{
            width: 100%;
            text-align: center;
            user-select: none;
            color: #aaaaaa;
            font-size: 18px;
        }
    }
}

.select-scroll{
    margin-top: 18px;
    padding: 10px 18px;
    border: 1px solid #cccccc;
    height: 180px;
    overflow-y: scroll;
}

