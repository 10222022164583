.module-detail-body{

    .main-tools{
        margin-bottom: 30px;

        .ant-btn{
            padding: 4px 30px !important;
        }

    }

    .main-details{
        align-items: flex-start;
        margin-bottom: 30px;

        .detail-label{
            width: 80px;
            flex-shrink: 0;
            display: block;
        }

        .detail-tips{
            display: block;
            color: #999999;
            margin-bottom: 20px;
        }

        .ql-container{
            min-height: 300px;
        }
    }

    .main-footer{

        .ant-btn{
            padding: 4px 30px !important;
        }

    }



}
