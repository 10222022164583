.main-content{
    
    .main-title{
        padding: 12px 24px;
        font-size: 18px;
        border-bottom: 1px solid #eee;
    }
    
    
}
.fill-theme-choice {
    .fill-box{
        margin-top: 6px;
    }
    .ant-form-item-label{
        label{
            font-weight: 600;
                &::before {
                    display: inline-block;
                    margin-right: 4px;
                    color: #ff4d4f;
                    font-size: 14px;
                    font-family: SimSun, sans-serif;
                    line-height: 1;
                    content: '*';
                }
            }
    }
}
.apply-direction{
    .icon-apply{
        margin-top: 8px;
        margin-right: 5px;
        margin-left: 4px;
        cursor: pointer;
    }

    .icon-name{
        margin-top: 5px;
        margin-right: 8px;
        cursor: pointer;
        user-select: none;
    }
}
