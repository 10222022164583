.activities-page {
    .activities-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            margin: 0 10px 0 0;
        }
    }

    .activities-content {
        .activities-search {
            margin: 20px 0;

            .ant-form  {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .ant-form-item {
                    margin-bottom: 0;
                }
            }

            .ant-space {
                gap: 0px !important;

                .ant-space-item {
                    margin-left: 8px;

                    &:last-of-type {
                        margin-left: 1px;
                    }
                }
            }
        }
    }
}

.add-activities-modal {
    .relate-activities-form { 
        .ant-form-item-row {
            display: block !important;
        }
    }

    .ant-form-item {
        margin-bottom: 10px;
    }
}

.activities-name-sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border: 1px solid #eeeeee;
    padding: 10px;

    &.active {
        background-color: #e6f7ff;
        color: #1890ff;
    }

    .activities-item-name {
        display: inline-block;
        flex: 1;
    }

    .anticon {
        margin-left: 10px;
        cursor: pointer;
    }

    &:hover {
        background-color: #e6f7ff;
        color: #1890ff;
    }
}
