.aq-detail {
  border-bottom: 1px solid #eee;
  padding-bottom: 24px;

  .detail-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title-name {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 30px;
    }
  }

  .question-audio {
    margin-top: 20px;
    width: 300px;
  }
}

.detail-cotent-title {
  display: flex;
  align-items: center;
  height: 48px;

  .title-name {
    margin-right: 24px;
  }
}

.event-content {
  margin-top: 20px;
}

.detail-tag {
  display: flex;
  align-items: center;

  .tag-item {
    font-size: 12px;
    height: 30px;
    color: blue;
    border: 1px #1a68d3 solid;
    border-radius: 4px;
    padding: 6px 12px;
    margin-right: 6px;
  }
}

.answer-audio,
.audit-audio {
  margin-top: 20px;
  width: 300px;
}

.item-content {
  margin-top: 10px;
}
