.project-statistics {
  margin-top: 50px;
  .date-limit {
    margin-left: 20px;
    font-size: 14px;
    color: #aaa;
  }

  .online-date-choice {
    margin-bottom: 18px;

    .ant-picker {
      height: 32px;
      display: inline-block;
    }

    .ant-picker-range {
      display: inline-flex;
    }
  }

  .online-project-choice {
    margin-bottom: 18px;

    .ant-select {
      width: 280px !important;
      margin-right: 10px;
    }

    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }
  
}

.online-date-title {
  overflow: hidden;
  font-size: 18px;
  margin-bottom: 10px;
}

.projectTableWrap {
  .ant-table .ant-table-tbody {
    tr:last-of-type {
      background: rgba(51, 123, 255, 0.07);
  
      &:hover td {
        background: rgba(51, 123, 255, 0.07) !important;
      }
    }

    tr:first-of-type {
      background: #ffffff;
      &:hover td {
        background: #ffffff;
      }
    }
  }
}

