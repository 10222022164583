.custom-right-box{
    flex: 1;
    background-color: #f0f2f5;
    padding: 30px 24px 0;
    box-sizing: border-box;
    min-height: 88vh;
    .custom-right{
        background-color: #ffffff;
        width: 100%;
        min-height: 80vh;
        padding: 30px;
        .switch-label{
            margin-right: 10px;
        }
        .switchtype{
            font-size: 16px;
            font-weight: 600;
        }
        .upload-btn,.save-btnarea{
            margin-left: 135px;
            margin-top: 20px; 
            .upload-helper{
                color: #aaa;
                font-size: 14px;
                margin-top: 10px;
            }
            .ant-upload-list-item-info{
                width: 840px;
                .ant-upload-list-item-name{
                    flex: none;
                    margin: 0;
                    padding: 0 8px;
                    width: 800px;
                    overflow: hidden;
                }
            }
            
        }
    }
}

