.projects-form {
    .field-group {
        .field-title {
            font-size: 14px;
            opacity: 0.6;
            margin-right: 20px;
        }
    }

    .radio-list {
        display: flex;
        flex-direction: column;
    }

    .raceDesc-input {
        width: 350px !important;
    }

    .projects-icon-main {
        display: flex;
        align-items: center;
        margin-top: 4px;

        .icon-title-projects {
            cursor: pointer;
            user-select: none;
        }

        .icon-pointer-projects {
            cursor: pointer;
            margin-right: 2px;
        }
    }

    .projects-race-form-list-space {
        margin-bottom: 10px;
    }

    .ant-form-item-margin-offset {
        margin-bottom: 0 !important;
    }

    .close-img {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 190px;
        top: -10px;
        color: #FFFFFF;
        cursor: pointer;
    }

}
