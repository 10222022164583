.importDataModal .ant-modal-body {
  height: 300px;

  .fileInfo {
      margin-left: 70px;
      margin-top: 10px;

      p:last-of-type {
          color: #666666;
      }
  }
}

.confirmImportDataModal {
  width: calc(100vw - 100px) !important;

  .ant-modal-content {
      width: 100%;
      height: calc(100vh - 100px);

      .ant-modal-body {
        //   overflow-y: auto;
          height: calc(100% - 120px);

          .fileInfo {
              margin-left: 70px;
              margin-top: 10px;

              p:last-of-type {
                  color: #666666;
              }
          }
      }
  }
}
