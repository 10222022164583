.contents-page {
    .contents-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            margin: 0 10px 0 0;
        }
    }
}
