@import '~antd/es/style/themes/default.less';

.site-module {
  &.site-module-home {

    .color-field {
      display: flex;
    }

    .color-previewer {
      width: 32px;
      height: 32px;
      margin-left: 8px;
    }

    .quick-links {
      .quick-link {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }

      .quick-link-title {
        width: 200px;
      }

      .quick-link-url {
        flex: 1;
        padding-left: 10px;
      }

      .operations {
        width: 100px;

        > .anticon {
          margin-left: 10px;
        }
      }

    }

  }
}

.phone-webview-box {

  .phone-webview-inner-box {
    display: flex;
    justify-content: space-between;
  }

  .phone-webview {
    position: relative;
    width: 375px;
    border: 1px solid #CCC;
    min-height: 650px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% auto;

    .navigation {
      position: absolute;
      top: 0;
      left: 0;
      width: 375px;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      background-color: #5a5a5a;
      z-index: 99;

      .brand-logo {
        flex: 1;
        img {
          width: auto;
          height: 40px;
        }
      }

      .nav-trigger {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 30px;
        height: 30px;

        svg {
          font-size: 20px;

          color: white;
        }
      }

    }

    .need-upload {
      position: relative;
      background-color: @yellow-3;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .7);
        content: " ";
        z-index: 1;
        transition: all .3s;
        opacity: 0;
      }

      .actions {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s;
        z-index: 10;
        opacity: 0;

        .ant-btn-text {
          color: white;
        }
      }

      &:hover {
        &::before {
          opacity: 1;
        }

        .actions {
          opacity: 1;
        }
      }

    }

    .banner {
      height: 260px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;

      &.banner-five {
        height: 350px;
      }

      &.banner-two {
        height: 506px;
      }
    }

    .matrix {
      padding: 8px;

      &.grid_number_one {
        .matrix-row {
          margin: 0 auto;
        }
      }

      .matrix-row {
        display: flex;
        justify-content: space-between;

        .margin-bottom {
          margin-bottom: 5px;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      .matrix-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      // 通栏
      // 上传 718x172px
      .block {
        width: 357px;
        height: 85.5px;
      }

      // 大正方形
      // 上传 354x354px
      .square {
        width: 176px;
        height: 176px;
      }

      // 小正方形
      // 上传 172x172px
      .square-small {
        width: 85.5px;
        height: 85.5px;
      }

      // 长方形
      // 上传 354x172px
      .rectangle {
        width: 176px;
        height: 85.5px;
      }

    }


  }

  .matrix-tips {
    flex: 1;
    padding-left: 24px;

    img {
      width: 100%;
      border: 1px solid #CCC;
    }

    p {
      text-align: center;
      color: #888;
    }
  }

}


