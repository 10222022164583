.main-content-box.mark-table-content {
    padding: 20px;

    .mark-table-header {
        &-button {
            display: flex;
            gap: 12px;
        }

        &-search {
            display: flex;
            gap: 12px;
            align-items: center;
            margin-bottom: 16px;

            input {
                width: 200px;
            }
        }
    }
}

.pingwei-checkgroup.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

