.cover-setting{
    .settiing-title{
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
        margin-bottom: 20px;
        padding-left: 10px;
    }
    .setting-box{
        padding: 2px 3px;
        .has-title,.has-copyright,.has-logo,.has-covercolor{
            display: flex;
            justify-content: space-between;
            border-left:4px solid #1a73e8;
            padding-left: 8px;
            margin-left: 10px;
            margin-bottom: 30px;
            user-select: none;
        }
        .has-covercolor{
            display: block;
            margin-bottom: 10px;
        }
        .font-group{
            margin-top: -10px;
            margin-bottom: 20px;
            margin-left: 30px;
            .color-picker-box{
               
            }
            .ant-input-group.ant-input-group-compact{
                display: flex !important;
                justify-content: center !important;
            }
            .ant-select{
                width: 80px;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                background: #f2f2f2 !important;
            }
        }
    }
    .has-covercolor-setting{
        width: 94%;
        height: 25px;
        background-color: #1a73e8;
        border-radius: 5px;
        margin: 10px;
        cursor: pointer;
    }
}
