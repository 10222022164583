.operation-info-footer{
    float: right;
}

.operation-info-content{
    .ant-descriptions-item-label{
        display: inline-block !important;
        width: 80px;
        text-align: right;
        margin-right: 10px;
    }
    
}
