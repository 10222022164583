.edit_col{
    display: flex;
    .col{
        text-align: center;
    }
    .col:hover{
        border: 1px dashed  #1a73e8 ;
    }
    .edit_action{
        display: flex;
        align-items: center;
    }
}
