.guest-selection-area {
  .guest-selection-title {
    position: relative;
  }

  .toolbar {
    position: absolute;
    right: 0;
    top: 0;
  }
}


