.replay-mp4-list {
  .replay {
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    padding: 8px 16px;
    border-radius: 4px;
    margin-bottom: 16px;

    .replay-info {
      flex: 1;
    }

    .replay-title {
      font-size: 16px;
      font-weight: 600;
    }

    .range {
      span {
        color: #888;
      }
    }

    .extra {
      display: flex;
      justify-content: flex-start;

      .duration {
        margin-right: 20px;
      }
      .file-size {}
    }

    a.link {
      //width: 80px;
    }
  }

}
