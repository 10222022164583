.question_item_wrap{
    margin-top: 30px;
    .question_statistics{
        display: flex;
       .title_color{
        color: #000 !important ;
        word-break: break-all;
        >p>span,>p>u,>p>strong{
            color: #000!important ;
            word-break: break-all;
        }       
       }
        .question_type{
            display: flex;
            width: 300px;
            align-items: flex-end;
            padding-bottom: 13px;
        }
    }
    >p{
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
    >span{
        display: block;
        margin: 10px 0;
        color: #666;
    }
    u{text-decoration:none !important;}
    strong{font-weight: 400;}
}

