@import '~antd/lib/style/themes/default.less';

.welcome {
  p:last-child {
    margin-bottom: 0;
  }

  .label {
    display: inline-block;
    color: #888;
    width: 80px;
  }
}

.ant-card.module {
  border: 1px solid transparent;
  background-color: #f7f9fa;
  border-radius: 2px;
  height: 100px;

  .module-title {
    font-weight: 600;
    margin-bottom: .5rem;
    text-align: center;
  }

  p {
    margin-bottom: 0;
    color: #888;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    border-color: #c0c6cc;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 16%);
  }

}


