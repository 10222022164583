.docBox {
    display: flex;
    align-items: center;
    background: rgba(133, 144, 166, 0.05);
    border-radius: 6px;
    padding: 8px;
    margin-bottom: 10px;

    .docIcon {
        display: flex;
        align-items: center;
        margin-right: 4px;
        color: #888;
    }

    .docName {
        flex: 1;
        color: #444;
    }

}

.btnWithIcon {
    display: flex !important;
    align-items: center;

    svg {
        margin-right: 4px;
    }
}
