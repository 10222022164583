.laborfeeRefuseModal{

    #laborfeeRefuseForm{
        margin-bottom: 40px;
    }

    .ant-row.ant-form-item-row{
        display: block;
    }

    .historyReasons{
        max-height: 300px;
        overflow-y: auto;

        &>span{
            display: block;
            margin-bottom: 5px;
        }

        .ant-list-vertical .ant-list-item-meta,.ant-list-item-meta-content>h4,.ant-list-item>p{
            margin-bottom: 0;
        }
    }
}

.payment-record-modal{
    .payment-record-wrap{
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        .payment-record-title{
            text-align: center;
        }

        .payment-info-laborfee{
            width: 100%;
            display: flex;
            justify-content: right;
            margin-bottom: 10px;

            &>span{
                margin-left: 20px;
            }
        }
        
        .payment-record-info,.payment-record-table{
            margin-bottom: 20px;
        }
    }

    .payment-record-btn{
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 10px;
    }
}

