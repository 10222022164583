.module-file-list{
    display: flex;
    flex-direction: column;
    height: 100%;
    
    .module-body{
        display: flex;
        align-items: start;
        .upload-container{
            .file-tip{
                font-size: small;
                color: #777;
            }
        }
    }
    .file-list{
        flex: 1;
        width: 620px;
        .file-item{
            display: flex;
            align-items: center;
            width: 100%;
            padding-top: 6px;
            .file-info{
                display: flex;
                border: #999 1px solid;
                border-radius: 4px;
                line-height: 20px;
                padding: 4px 10px;
                flex: 1;
    
                .bi{
                    width: 18px;
                    height: 18px;
                }
                .file-name{
                    margin-left: 2px;
                    font-size: 11px;
                    flex: 1;
                    color: #666;
                }
                .file-status{
                    font-size: 11px;
                    &.uploading{
                        color: #E8A046;
                    }
                    &.error{
                        color: #C83232;
                    }
                    &.success{
                        color: #86AD4E;
                    }
                    &.done{
                        color: #666;
                    }

                }

            }
            .operation {
                cursor: pointer;
      
                &:hover {
                  color: #222;
                }
      
                &.move {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0 4px;
      
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }

                &.down{
                    padding-left: 0;
                }
              }
        }
    }
}
