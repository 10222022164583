.setting-title{
    h1 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        border-left: 4px solid #1a73e8;
        padding-left: 12px;
    }
}

.setting-content{
    .ant-list-item-meta-description{
        color: #000;
        font-size: 16px;
    }
}

.setting-first{
    margin-bottom: 20px;
}

.hasWhitelist{
    padding: 0 15px !important;
    height: 23px !important;
}

.submit-num{
    display: flex;
    .change-num{
        margin-right: 20px;
    }
    .ant-form{
        height: 30px;
    }
    .bg-switch{
        margin-top: 4px;
    }
}

.change-num{
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none !important;
    }
}

