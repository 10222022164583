.bi-user-overview{
    margin: 24px 24px 0;
    .bi-user-overview-top{
        .ant-card-head{
            border-bottom: 0;
        }
        .ant-card-bordered{
            border: 0;
        }
        .ant-card{
            background-color: #fafafa;
        }
    }
    .ant-picker-input > input{
        width: 73px;
    }
}
.echarts-register{
    position: absolute;
    z-index: 100;
}

.ant-card-head-title{
    font-size: 16px;
}

.tooltip-box{
    position: relative;
    float: left;
    .user-overview-tooltip{
        position: absolute;
        top: 0;
        right: -20px;
        color: #bbb;
    }
}

.user-overview-link{
    color: #bbb;
}

.user-overview-num{
    font-size: 17px;
    font-weight: 600;
    margin-left: 20px;
}

.card-title{
    font-weight: 600;
    font-size: 16px;
    color: #5a5a5a;
}

.bi-user-overview-bottom{
    margin-top: 24px;
}

.register-ehcarts{
    width: 100%;
    height: 250px;
}

.profession-echarts{
    width: 100%;
    height: 300px;
}

.bi-user-overview-time-box-left{
    margin-right: 5px !important;
}
