
.experiences{
    .section{
        width: 90%;
    }
    .section-box{
        border: 1px solid #ddd;
        display: flex;
        margin-bottom: 10px;
        
        .section-index{
           width: 5%;
           display: flex;
           justify-content: center;
           align-items: center;
           border-right: 1px solid #ddd;
        }
        .section-content{
            flex: 1;
            padding:12px 0 5px 12px;
        }
    }
    .add-section-btn{
        margin-bottom: 10px !important;
    }
    .section-close{
        width: 30px;
    }
    .office-box{
        margin-top: 10px;
    }
    
    .adept{
        margin: 12px 1% 12px 5px;
    }
    .add-others{
        display: flex;
        padding: 10px;
        width:100%;   
        .section{
            width: 80%;
        }
        Button{
            height: 30px;
            width: 40px;
        }
       
    }
    .contacts{
        margin-left: -50px !important;
    }
    .others-context{
        flex: 1;
        padding:12px 0 5px 12px;
    }
    .other-email{
        padding: 10px 1% 0 10px!important;
    }
    
    .position{
        display: flex;
        width: 100%;
        .position-item{
            width: 30%;
        }
        .position-btn{
            width: 10px;
        }
    }
}
