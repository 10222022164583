.fracde-mobile{
    margin-top: -60px;
    .mobile-box{
        width: 100%;
       height:800px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.8);
        .mobile-final{
            height: 750px;
            min-width: 430px;
            position: relative;
           margin-top: 10px;
            background-image: url('../../../../../static/images/phone.png');
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            .modal-content{
                width: 90%;
                height:94%;
                transform: scale(1.02);
                margin-top:5%;
                position: relative;
                border-radius: 40px; 
                -moz-border-radius: 10px;
                -webkit-mask-image: -webkit-radial-gradient(white, black);
                background-color: black;
                // overflow: scroll;
                .iframe-width{
                    width: 100%;
                    height: 100%;
                    border: 1px soild #fff;
                  
                   
                }
            }
            .phone-header{
                width: 60%;
                position: absolute;
                z-index: 1;
                left: 20%;
                top: 1%;
            }
        }
    
        .survey-answer{
            opacity: 0;
        }
        .pointer-none{
            pointer-events: none;
        }
    }
}

