.checkacademy-research{
  .ant-col-md-6{
    display: block;
    flex: 0 0 35% ;
    max-width: 50% ;
  }
  .ant-col-md-18 {
    max-width: 50% ;
  }

}
.reserach_info_wrap{
  .ant-modal-confirm-content{
    div{
      padding-top: 10px;
      >div{
        display: flex;
        flex-direction: row;
        span{
          margin-right: 10px;
        }
      }
    }
  }
}
