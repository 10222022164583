.judge-option{
    display: flex;
    width: 100%;
    margin-left: 40px !important;
    cursor:default​​;
    span:nth-child(2){
        display: flex;
        align-items: center;
    }


    TextArea{
        width: 50vw;
        font-size: 18px;
        overflow-y:hidden
    }
    &:hover .opion-action{
            display: flex;
    }
}


