.judge-form-space {
    padding-left: 30px;
    // margin-bottom: 10px !important;

    &-select {
        width: 350px !important;
    }
}

.mark-icon-main {
    margin-top: 8px;
}

.judges-modal-topics-tips-box {
    display: flex;
    &-title {
        white-space: nowrap;
    }
    &-text {
        margin-left: 20px;
    }
}
