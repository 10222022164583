.modal-wrapper {
  .title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .experiences {
      margin-left: 12px;
    }
  }
}
