.martrix_data_box{
    margin-bottom: 10px;
}
.martrix-pc{
    max-height: 200px;
    position: relative;
    width: 1200px;
    overflow: auto;
    table{
        width: 100%;
        height:100px;
        table-layout: fixed;
        margin-bottom: 5px;
        overflow-y: scroll;
    }
    .fix_col{
        position:sticky;
        top:0;
        z-index: 10;
        &:first-child {
            position: sticky;
            left: -1px;
            z-index: 999;
          }
    }
    .fix_row{
        position: sticky;
        left: 0;
        z-index:9;
        background-color: #fff;
    }
    table th {
        width: 307px;
        height: 0.4rem;
        color: #333333;
        font-weight: 400;
        background: #F0F0F3;
        border: 1px solid #E7E7E7;

      }

      td {
        text-align: center;
        height: 0.4rem;
        color: #333333;
        border: 1px solid #E7E7E7;
        font-size: 0.16rem;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    .ant-input-disabled{
        background-color: #fff !important;
        border: none;
        cursor: auto;
        color: #000 !important;
        text-align: center;
    }
}
.table_page{
    float: right;
    margin-top:10px;
}

