.basis-question {
    background-color: #f7f7f7 !important;
    width: 80%;
    margin: 5px 15px;
}
.question-button-action{
    margin-top: 10px;
    height: calc( 100vh - 110px);
    overflow-y: auto;
    .question-action-title{
        font-size: 18px;
        margin: 20px 0 2px 13px;
    }
}
.question-item {
    height: 100%;
    // width: 98%;
    // margin-left: 5px !important;
    padding-left: 15px !important;
    padding-right: 10px !important;
    padding-bottom:10px !important;
    padding-top: 15px !important;
    margin-bottom: 0 !important;
    transition: all 0.5s;
    &:hover{
        box-shadow:  0px 0px 20px 0px rgba(0, 0, 0, .4);
        z-index: 2020;
        
    }
}
.question-select{
    height: 1px;
}
.question-require{
    margin-left: 20px !important;
}
.question-action {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .qusetion-action-right{
        display: flex;
        margin-right: 18px;
        div{
            margin-right: 10px;
        }
    }
}
.question-list{
    pointer-events: none;
}
.drop-item{
    cursor: pointer;
}

.question-area {
    height: calc( 100vh - 150px);
    // overflow-y: auto;
    .questions-box{
        height: 98%;
    }
    Input,textArea {
        border:1px solid transparent;
        margin-top: 10px;
    }
    .title{
        text-align: center;
        font-size: 30px;
    }
   
    .division{
        margin-top: 10px;
        color: #D3D3D3	;
        opacity: 0.5;
        padding: 0 10px;
    }
    .title:hover{
        border: 1px dashed  #1a73e8
    }
}
.select-question-switch{
    display: inline-block;
    margin-left: 10px;
    margin-right: 5px;
}
.select_range{
    display: inline-block;
    margin-left: 10px;
}


.introduce{
    overflow-y: hidden;
    font-size: 22px !important;
    width: 100%;
    .ql-toolbar.ql-snow {
        margin-bottom: 10px;
    }
    .ql-container{
        overflow-y: hidden;
        font-size: 24px !important;
        min-height: 22px !important;
        border:none;
        height: 120px;
    }
    .ql-toolbar{
        display: none;
        position:absolute;
        background:#fff;
        padding:0 2px;
        z-index: 9;
        top: -35px;
    }
    
        &:hover {
                border:1px dashed #1a73e8 ;
       }
    
}

.question_item_wrap{
    background-color: #fff;
    width: 100%;
    >.child_container_wrap{
        padding-left: 24px;
    }
}

.drop-item{
    cursor: pointer;
}
