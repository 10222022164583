.guest-info-drawer {
    .drawer-main {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            &-title {
                font-size: 16px;
                font-weight: 600;
                color: #222;
                display: flex;
                align-items: center;
            }
        }

        .drawer-main-left {
            width: 48.5%;
            padding: 20px 30px;
            border-radius: 4px;
            border: 1px solid #e3e4e6;
            transition: box-shadow .3s;
        }

        .drawer-main-right {
            width: 48.5%;
            padding: 20px 30px;
            border-radius: 4px;
            border: 1px solid #e3e4e6;
            transition: box-shadow .3s;
        }

        .form-check-invitation {
            width: 100%;
            display: flex;
            align-items: baseline;

            .form-item-invitation {
                flex: 1;
            }
        }
    }

    .section-box {
        border: 1px solid #ddd;
        display: flex;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .section-index {
            width: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: 1px solid #ddd;
        }

        
        .section-content-invitation {
            flex: 1;
            padding: 15px 20px;
            
            .form-list-work-title {
                margin-bottom: 30px;
            }

            .form-list-item-invitation {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

            .form-list-work {
                margin-bottom: 15px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 10px 10px;

                &:last-child {
                    margin-bottom: 0;
                }

            }

            .form-list-work-item-invitation {
                margin-bottom: 15px;

                .ant-form-item-label {
                    min-width: 80px;
                    text-align: right
                }

                &:last-child {
                    margin-bottom: 0;
                }


            }
        }
    }

    .preview-modal {
        width: 550px !important;
    }
}
