.main-content-box.assign-content {
    padding-top: 20px;
    
    .flex-span {
        display: flex;
        gap: 8px;
        align-items: center;

        .flex-span-1 {
            width: 40%;
        }

        & > span {
            flex-shrink: 0;
        }

        .delete-span {
            color: #ff0000;
            cursor: pointer;
        }
    }

    .operation-buttons {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin-top: 40px;
        width: 100%;

        button {
            width: 120px;
        }
    }
    
    .flex-from-item {
        display: flex;
        align-items: center;

        .ant-form-item {
            margin: 0;
        }
    }
}

