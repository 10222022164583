.surveys-content{
    margin: 24px 24px 0;
}

.surveys-new-content-box{
    padding-bottom: 20px;
}

.surveys-content-left{
    background-color: #fff;
    margin-right: 20px;
    text-align: center;
    .surveys-content-left-top{
        margin-top: 15px;
        font-size: 18px;
        font-weight: 600;
    }
    .surveys-content-left-bottom{
        margin-top: 20px;
        p{
            margin-bottom: 20px !important;
        }
    }
}

.click-surveys{
    cursor: pointer;
}

.surveys-content-right{
    padding: 0 !important;
}
.surveys-content-right-title{
    background-color: #fff;
    padding: 15px 20px;
    p{
        margin-top: 10px;
        padding-left: 20px;
        background-color: #f2f2f2;
        height: 30px;
        line-height: 30px;
        margin-bottom: 0 !important;
    }
}

.surveys-content-right-content{
    margin-top: 20px;
}
.radio-text-top{
    position: relative;
}
.radio-text-num{
    position: absolute;
    line-height: 32px;
}
.radio-text-button{
    position: absolute;
    top: 0;
    right: 50px;
}
.radio-text-center{
    input{
        margin-left: 20px;
    }
}

// 单选题
.radio-text{
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    .ant-radio-wrapper{
        span+span{
            width: 600px;
        }
    }
}
.checkbox-text{
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    .ant-checkbox-wrapper{
        span+span{
            width: 600px;
        }
    }
}
.supply-blank-text{
    padding: 20px;
    background-color: #fff;
    margin-bottom: 20px;
    textarea{
        width: 600px;
        margin-left: 30px;
    }
}

.add-option-list{
    margin-top: 10px;
    margin-left: 30px;
    color: #02a7f0;
}
.radio-text-content-part{
    position: relative;
    width: 624px;
}
.delete-icon{
    position: absolute;
    right: -10px;
    font-size: 18px;
    margin-top: 6px;
    color: #1296db !important;
}
.surveys-content-right-content{
    .tip{
        margin-top: 20px;
        height: 400px;
        line-height: 400px;
        text-align: center;
        background-color: #fff;
        font-size: 17px;
    }
}
.surveys-publish-time{
    background-color: #fff;
    padding: 15px 20px;
    margin-top: 20px;
    .ant-form-item{
        margin-bottom: 7px;

    }
}

.tip-title{
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
    text-align: center;
    color: #999;
    margin-bottom: 20px;
}

.preview-content{
    padding: 20px 30px;
    border: 1px solid #ddd;
}

.preview-title{
    text-align: center;
    font-weight: 600;
}

.preview-time{
    border-bottom: 1px solid #02a7f0;
    padding-bottom: 12px;
}
.radio-preview{
    margin-bottom: 10px;
}

.radio-preview-center{
    margin-bottom: 10px;
}
.radio-preview-content{
    margin-left: 8px;
}
